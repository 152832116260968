/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  showPriceValue,
  shorten,
  createScriptForAddToCart
} from "../Helpers/SettingHelper";
import cartLike from "../../common/images/cat-item-like-bw.png";
import cartLikeActive from "../../common/images/cat-item-like-bw-active.png";
import noimage from "../../common/images/no-img-product.png";
import { isNull } from "underscore";
import { GET_FAVOURITELIST, GET_FAVOURITE } from "../../actions";
import Parser from 'html-react-parser';
var qs = require("qs");

class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedNavigation: "",
      selectedslugType: "",
      productSubcatlist: [],
      productCommon: [],
      product_List: [],
      filter_Tag: [],
      imagesource: "",
      viewProductSlug: "",
      OldupdateproductList: [],
      oldnavigateMenu: [],
      selProductEndSection: [],
      FinalDetailsProduct: [],
      voucherProductDetail: [],
      voucher_product_qty: "",
      voucher_name: "",
      voucher_mobile: "",
      voucher_email: "",
      voucher_message: "",
      error_voucher_name: "",
      error_voucher_email: "",
      error_voucher_message: "",
      error_voucher_mobile: "",
      voucherIndexFlag: "",
      favproductID: [],
      favdeleteproductID: [],
      loading: true,
      favouriteproduct: [],
    };
    this.props.getFavouriteproductslist();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.productSubcatlist !== nextProps.productSubcatlist) {
      this.setState(
        { productSubcatlist: nextProps.productSubcatlist, loading: false },
        function () {
          this.productsubcatlist();
        }
      );
    }
    if (this.state.favouriteproduct !== nextProps.favouriteproduct) {
      this.setState(
        { favouriteproduct: nextProps.favouriteproduct },
        function () {
          this.props.getFavouriteproductslist();
        }
      );
    }
  }

  componentDidMount() {
    $(document).ready(function () {
      $(".tab_item").click(function () {
        $(".product_tab_item").removeClass("tab_item_active");
        $(
          ".product_tab_item[data-id='" + $(this).attr("data-id") + "']"
        ).addClass("tab_item_active");
        $(".tab_item").removeClass("active");
        $(this).parent().find(".tab_item").addClass("active");
      });
    });
  }

  productsubcatlist() {
    var subcatlistArr = this.props.productSubcatlist;
    if (Object.keys(subcatlistArr).length > 0) {
      var product_List = (
        <div className="product_list_div active">
          <div className="product-category-list">
            <div className="innerproduct">
              <ul className="products-list-ulmain">
                {this.productList(subcatlistArr)}
              </ul>
            </div>
          </div>
        </div>
      );
      this.setState({ product_List: product_List }, function () {
        hideLoader("productlist-main-div", "class");
        $("#dvLoading").fadeOut(1000);
        var totalProducts = 0;
        if ($(".innerproduct").length > 0) {
          $(".innerproduct").map(function () {
            if ($(this).find(".products-list-ulmain li").length > 0) {
              totalProducts++;
            }
          });
          if (totalProducts > 0) {
            $("#no_product_error").hide();
          } else {
            $("#no_product_error").show();
          }
        }
      });
    } else {
      if (this.state.loading === false) {
        this.setState({ product_List: [] });
        $("#no_product_error").show();
      }
    }
  }

  chkProStockCls(proSlug, Stock) {
    var searchProVal = this.props.productState.searchProVal;
    var actClstxt = searchProVal === proSlug ? " active" : "";

    var returnText =
      "products-single-li no-stock-product " + proSlug + actClstxt;
    if (Stock > 0) {
      returnText = "products-single-li " + proSlug + actClstxt;
    }
    return returnText;
  }

  addFavourite(productID, fav_flag) {
    var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
    if (customerId !== "") {
    var formData = {
      app_id: appId,
      product_id: productID,
      fav_flag: fav_flag,
      customer_id: cookie.load("UserId"),
    };
    var favproductID = this.state.favproductID;
    var favdeleteproductID = this.state.favdeleteproductID;
    if (fav_flag === "Yes") {
      favproductID.push(productID);
      const index1 = favdeleteproductID.indexOf(productID);
      if (index1 > -1) {
        favdeleteproductID.splice(index1, 1);
      }
    } else {
      const index = favproductID.indexOf(productID);
      if (index > -1) {
        favproductID.splice(index, 1);
      }
      favdeleteproductID.push(productID);
    }
    this.setState(
      { favproductID: favproductID, favdeleteproductID: favdeleteproductID },
      function () {
        this.productsubcatlist();
      }
    );

    var postObject = qs.stringify(formData);
    this.setState({ favouriteproduct: [] });
    this.props.getFavouriteproducts(postObject);
    } else {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  /* Products List */
  productList(list) {
    var imageSource = this.props.productCommon.product_image_source;
    const listProduct = list.map((productDetail, index) => {
      var description =
        productDetail.product_short_description !== null &&
        productDetail.product_short_description !== ""
          ? Parser(stripslashes(productDetail.product_short_description))
          : "";

      var prodivId = "proIndex-" + productDetail.product_primary_id;
      var comboProId = "comboPro-" + productDetail.product_slug;

      return (
        <li
          className={this.chkProStockCls(
            productDetail.product_slug,
            productDetail.product_stock
          )}
          id={prodivId}
          key={index}
        >
          <div id={"prolist" + prodivId} className="product_list_sec">
            {this.state.customerId !== "" ? (
              <div>
                {this.state.favdeleteproductID.length > 0 &&
                this.state.favdeleteproductID.indexOf(
                  productDetail.product_primary_id
                ) >= 0 ? (
                  <a
                    href={void 0}
                    className="like"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  >
                    <img src={cartLike} className="no_mou" />
                    <img src={cartLikeActive} className="active-hart mou_active" />
                  </a>
                ) : (!isNull(productDetail.fav_product_primary_id) &&
                    typeof productDetail.fav_product_primary_id !== undefined &&
                    typeof productDetail.fav_product_primary_id !==
                      "undefined") ||
                  this.state.favproductID.indexOf(
                    productDetail.product_primary_id
                  ) >= 0 ? (
                  <a
                    href={void 0}
                    className="like active"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "No"
                    )}
                  >
                    <img src={cartLike} className="no_mou"/>
                    <img src={cartLikeActive} className="active-hart mou_active" />
                  </a>
                ) : (
                  <a
                    href={void 0}
                    className="like"
                    onClick={this.addFavourite.bind(
                      this,
                      productDetail.product_primary_id,
                      "Yes"
                    )}
                  >
                    <img src={cartLike} className="no_mou" />
                    <img src={cartLikeActive} className="active-hart mou_active" />
                  </a>
                )}
              </div>
            ) : (
              <div>
                <a
                  href={void 0}
                  className="like"
                  onClick={this.addFavourite.bind(
                    this,
                    productDetail.product_primary_id,
                    "Yes"
                  )}
                >
                  <img src={cartLike} className="no_mou" />
                  <img src={cartLikeActive} className="active-hart mou_active" />
                </a>
              </div>
            )}

            <div className="products-image-div">
              {productDetail.product_thumbnail !== "" ? (
                <img
                  src={imageSource + "/" + productDetail.product_thumbnail}
                />
              ) : (
                <img src={noimage} />
              )}
            </div>

            <div className="product-info-div">
              <div className="product-title-maindiv">
                <div className="product-title">
                  <h3>
                    {productDetail.product_alias !== ""
                      ? stripslashes(productDetail.product_alias)
                      : stripslashes(productDetail.product_name)}
                  </h3>
                </div>
              </div>
              <p>{productDetail.product_short_description != "" &&
                  productDetail.product_short_description != null
                  ? Parser(
                      stripslashes(shorten(productDetail.product_short_description))
                    )
                  : Parser("")}
              </p>
              <div className="products-ordernow-action">
                <div className="product-price">
                  <h3>{showPriceValue(productDetail.product_price)}</h3>
                </div>

                {productDetail.product_stock > 0 ||
                productDetail.product_stock === null ? (
                  productDetail.product_type === "1" ||
                  productDetail.product_type === "5" ? (
                    <a
                      className="button order_nowdiv smiple_product_lk disbl_href_action"
                      /*href="/"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "initial"
                      )}*/
                      href={
                        "/products/" +
                        productDetail.pro_cate_slug +
                        "/" +
                        productDetail.pro_subcate_slug +
                        "/" +
                        productDetail.product_slug
                      }
                      onClick={(e) => {
                        showLoader(comboProId);
                      }}
                    >
                      Add to cart
                    </a>
                  ) : (
                    <a
                      href={
                        "/products/" +
                        productDetail.pro_cate_slug +
                        "/" +
                        productDetail.pro_subcate_slug +
                        "/" +
                        productDetail.product_slug
                      }
                      onClick={(e) => {
                        showLoader(comboProId);
                      }}
                      /*  href="/"
                    onClick={this.viewProDetail.bind(
                      this,
                      productDetail,
                      pro_subcate_slug
                    )} */
                      title="Product Details"
                      id={comboProId}
                      className="button order_nowdiv compo_product_lk"
                    >
                      Order Now
                    </a>
                  )
                ) : (
                  <a
                    className="button order_nowdiv disabled disbl_href_action"
                    href={void 0}
                  >
                    Sold Out
                  </a>
                )}

                <div className="addcart_row addcart_done_maindiv">
                  <div className="addcart-row-child">
                    {/*<div className="qty_bx">
                      <span
                        className="qty_minus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "decr"
                        )}
                      >
                        -
                      </span>
                      <input
                        type="text"
                        className="proqty_input"
                        readOnly
                        value="1"
                      />
                      <span
                        className="qty_plus"
                        onClick={this.proQtyAction.bind(
                          this,
                          productDetail.product_primary_id,
                          "incr"
                        )}
                      >
                        +
                      </span>
                    </div>
                    <button
                      className="btn btn_black"
                      onClick={this.addToCartSimple.bind(
                        this,
                        productDetail,
                        "done"
                      )}
                    >
                      Done
                    </button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      );
    });

    return listProduct;
  }

  checkFilterTag(productFilter) {
    var current = this;
    if (
      this.state.filter_Tag !== "" &&
      typeof this.state.filter_Tag !== undefined &&
      typeof this.state.filter_Tag !== "undefined" &&
      this.state.filter_Tag.length > 0
    ) {
      if (Object.keys(productFilter).length > 0) {
        let tagAvil = 0;
        productFilter.map(function (item) {
          if (current.state.filter_Tag.indexOf(item.tag_id) >= 0) {
            tagAvil++;
          }
        });
        if (tagAvil > 0) {
          return 1;
        } else {
          return 0;
        }
      }
    } else {
      return 1;
    }
  }

  proQtyAction(indxFlg, actionFlg) {
    var proqtyInput = $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val();
    proqtyInput = parseInt(proqtyInput);

    if(actionFlg === "decr" && proqtyInput == 1){
      $("#proIndex-" + indxFlg)
          .find(".smiple_product_lk")
          .show();
      $("#proIndex-" + indxFlg)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + indxFlg)
      .find(".product-list-main-div")
      .removeClass('add-cart');        
    }

    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }
    $("#proIndex-" + indxFlg)
      .find(".proqty_input")
      .val(proqtyInput);
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg, event) {
    event.preventDefault();

    if (productDetail.product_type === "5") {
      /* if (
        cookie.load("orderDateTime") === "" ||
        typeof cookie.load("orderDateTime") === undefined ||
        typeof cookie.load("orderDateTime") === "undefined"
      ) {
        if (this.props.productState.pickup_outlet.length == 0) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else if (this.props.productState.pickup_outlet.length > 1) {
          $.magnificPopup.open({
            items: {
              src: "#takeaway-popup",
            },
            type: "inline",
          });
        } else {
          this.props.sateValChange("openOutletTime", true);
        }
        return false;
      } */

      var IndexFlg = productDetail.product_primary_id;

      var proqtyQty = $("#proIndex-" + IndexFlg)
        .find(".proqty_input")
        .val();

      $.magnificPopup.open({
        items: {
          src: "#vouchergift-popup",
        },
        type: "inline",
      });
      this.setState({
        voucherProductDetail: productDetail,
        voucher_product_qty: proqtyQty,
        voucherIndexFlag: IndexFlg,
      });
    } else {
      var orderOutletId = cookie.load("orderOutletId");
      if (orderOutletId === "" || orderOutletId === undefined) {
        cookie.save("redirectfavourite", "Yes", { path: "/" });
        $.magnificPopup.open({
          items: {
            src: "#order-popup",
          },
          type: "inline",
        });
        return;
      }

      var IndexFlg = productDetail.product_primary_id;

      if (actionFlg === "initial") {
        $("#proIndex-" + IndexFlg).addClass("active");
        $("#proIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .hide();
        $("#proIndex-" + IndexFlg)
          .find(".addcart_done_maindiv")
          .show();
        return false;
      } else {
        showLoader("proIndex-" + IndexFlg, "Idtext");
        var availabilityId = cookie.load("defaultAvilablityId");
        /*var availabilityId = deliveryId;*/
        var availabilityName =
          availabilityId === deliveryId ? "Delivery" : "Pickup";
        var isAddonProduct = "No";
        var productId = productDetail.product_id;
        var customerId =
          typeof cookie.load("UserId") === "undefined"
            ? ""
            : cookie.load("UserId");
        var proqtyQty = $("#proIndex-" + IndexFlg)
          .find(".proqty_input")
          .val();
        var postObject = {};
        postObject = {
          app_id: appId,
          product_id: productId,
          product_qty: proqtyQty,
          product_type: 1,
          availability_id: availabilityId,
          availability_name: availabilityName,
          isAddonProduct: isAddonProduct,
          reference_id: customerId === "" ? getReferenceID() : "",
          customer_id: customerId,
        };

        Axios.post(
          apiUrlV2 + "cart/simpleCartInsert",
          qs.stringify(postObject)
        ).then((res) => {
          $("#proIndex-" + IndexFlg).removeClass("active");
          hideLoader("proIndex-" + IndexFlg, "Idtext");
          $("#proIndex-" + IndexFlg)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + IndexFlg)
            .find(".smiple_product_lk")
            .show();
          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            if (appId === "93EDF976-7242-4864-B57E-7B49306521AC") {
              createScriptForAddToCart();
            }
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
          }
          return false;
        });
      }
    }
  }

  addToCartVoucher() {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = this.state.voucherProductDetail.product_id;
      var IndexFlg = this.state.voucherProductDetail.product_primary_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        this.state.voucherProductDetail.product_price *
        this.state.voucher_product_qty;

      var ImagePath = this.state.voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.state.productimagePath +
          this.state.voucherProductDetail.product_thumbnail;
      } else {
        postImagePath = "";
      }

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: this.state.voucherProductDetail.product_id,
        product_qty: this.state.voucher_product_qty,
        product_name: this.state.voucherProductDetail.product_name,
        product_sku: this.state.voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        product_unit_price: this.state.voucherProductDetail.product_price,
        product_remarks: "",
        product_type: 5,
        modifiers: "",
        menu_set_component: "",
        availability_id: availabilityId,
        product_image: postImagePath,
        product_edit_enable: "No",
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_gift_name:
          this.state.voucher_name !== ""
            ? this.state.voucher_name
            : cookie.load("UserFname"),
        voucher_gift_mobile:
          this.state.voucher_mobile !== "" ? this.state.voucher_mobile : "",
        voucher_gift_email:
          this.state.voucher_email !== ""
            ? this.state.voucher_email
            : cookie.load("UserEmail"),
        voucher_gift_message:
          this.state.voucher_message !== "" ? this.state.voucher_message : "",
        product_voucher_expiry_date:
          this.state.voucherProductDetail.product_voucher_expiry_date !== ""
            ? this.state.voucherProductDetail.product_voucher_expiry_date
            : "",
      };
      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();

          if (res.data.status === "ok") {
            this.props.sateValChange("cartflg", "yes");
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              voucherProductDetail: "",
              voucher_product_qty: "",
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  addToCartVoucherMe() {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/
    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var productId = this.state.voucherProductDetail.product_id;
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      this.state.voucherProductDetail.product_price *
      this.state.voucher_product_qty;

    var postObject = {};
    postObject = {
      app_id: appId,
      product_id: this.state.voucherProductDetail.product_id,
      product_qty: this.state.voucher_product_qty,
      product_type: 5,
      availability_id: availabilityId,
      product_name: this.state.voucherProductDetail.product_name,
      product_sku: this.state.voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: this.state.voucherProductDetail.product_price,
      product_remarks: "",
      product_type: 5,
      modifiers: "",
      menu_set_component: "",
      availability_id: availabilityId,
      product_image: this.state.voucherProductDetail.product_thumbnail,
      product_edit_enable: "No",
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        this.state.voucherProductDetail.product_voucher_expiry_date !== ""
          ? this.state.voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();

      if (res.data.status === "ok") {
        this.props.sateValChange("cartflg", "yes");
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          voucherProductDetail: "",
          voucher_product_qty: "",
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  viewProDetail(productDetail, pro_cate_slug, pro_subcate_slug, event) {
    event.preventDefault();

    this.props.history.push(
      "/menu/" +
        pro_cate_slug +
        "/" +
        pro_subcate_slug +
        "/" +
        productDetail.product_slug
    );
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.props.sateValChange("cartflg", value);
    }
    if (field === "selProductEndSection") {
      this.setState({ selProductEndSection: "" }, function () {
        this.productsubcatlist(
          this.state.OldupdateproductList,
          this.state.oldnavigateMenu
        );
      });
    }
  };

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  render() {
    return (
      <div className="productlist-main-div">
        <div className="innerproduct_row">
          {this.state.product_List}
          <div
            className="no-product"
            id="no_product_error"
            style={{ display: "none" }}
          >
            <div className="container">
              <div className="no-recrds-found">
                <h2>No product found</h2>
                <p>  Sorry, we can't find the type of products you are looking for.
                  Try widening your search to see what’s available.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var favouriteproductlist = Array();
  var productCmn = Array();
  if (Object.keys(state.favouriteproductlist).length > 0) {
    var tempArr = state.favouriteproductlist;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        favouriteproductlist = tempArr[0].result_set;
        productCmn = tempArr[0].common;
      } else if (tempArr[0].status === "error") {
        $("#dvLoading").fadeOut(1000);
      }
    }
  }
  console.log(state.favouriteproduct, "state.favouriteproduct");
  return {
    productSubcatlist: favouriteproductlist,
    productCommon: productCmn,
    favouriteproduct: state.favouriteproduct,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFavouriteproductslist: () => {
      dispatch({ type: GET_FAVOURITELIST });
    },
    getFavouriteproducts: (formPayload) => {
      dispatch({ type: GET_FAVOURITE, formPayload });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(ProductList);
