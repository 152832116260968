/* eslint-disable */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link} from 'react-router-dom';
import axios from 'axios';
import Moment from 'moment';
import cookie from 'react-cookies';
import Parser from 'html-react-parser';

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import { stripslashes, addressFormat, getCalculatedAmount, callImage, showLoader, hideLoader, showAlert } from "../Helpers/SettingHelper";
import { appId, deliveryId, madbarId, CountryTxt, apiUrlNotify } from "../Helpers/Config";
import { GET_ORDER_DETAIL, DESTROY_CART_DETAIL } from '../../actions';


import thnkyou_tick_img from "../../common/images/tick.svg";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import pickupImg from "../../common/images/takeaway_old-b.png";
import cartMpImg from "../../common/images/cart-map.png";



class Thankyou extends Component {
	  constructor(props) {
		super(props);
		this.props.destroyCartDetail();
	  }
	  
	  componentWillMount() {
    let orderId = (typeof this.props.match.params.orderId !== "undefined" ? this.props.match.params.orderId : "");
		var chkOrderid = (typeof cookie.load('ChkOrderid') === 'undefined') ? '' : cookie.load('ChkOrderid');
		if(orderId !== '' && chkOrderid === orderId) {	
			this.props.getOrderDetail(orderId);
			this.sendNotification();
		} else {
			showAlert('Error', 'Invalid order detail.');
			$.magnificPopup.open({
				items: {
				  src: '.alert_popup'
			    },
			    type: 'inline'
			});
			this.props.history.push('/');
		}
	  }
	  
	  componentDidMount() {
		/*console.log('DidMount');*/
	  }
	  
	  sendNotification() {
		  axios.get(apiUrlNotify+'order_notify').then(res => { }); 
	  }
	  
	  loadItems(orderDetail) {
		if (Object.keys(orderDetail).length > 0) {

			var cartItems = orderDetail['items'];
			return cartItems.map((item, index) =>
		
					<div className="tnkyou-productdiv-in" key={index}>
					
							<div className="tnkyou-productdivin-left">
								<div className="tnkyou-leftimg">
									<a href="javascript:void(0)" title="">
										<img src={(item.item_image !== '')?item.item_image:productImg} alt="" />
									</a>
								</div>
								<div className="tnkyou-leftcontnt">
									<h4>{stripslashes(item.item_name)} X {item.item_qty}</h4>

									{this.loadModifierItems("Component", item.modifiers, item.set_menu_component)}
								</div>
								{item.item_specification !== '' && <p className="help-block">{stripslashes(item.item_specification)}</p>}
							</div>

							<div className="tnkyou-roductdivin-right">
								<p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
							</div>
					
					</div>

				 
			);
		} else { }
	}
	
	/* this function used to load modifer items */
	loadModifierItems(itemType, modifiers, combo) {
		var len = modifiers.length;
		var comboLen = combo.length;
		var html = '<div className="cart_extrainfo">';

		if (len > 0) {
			for (var i = 0, length = len; i < length; i++) {
				var modName = modifiers[i]['order_modifier_name'];
				var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
				html += "<p><b>" + stripslashes(
					modName) + ":</b></p><p> " + stripslashes(
						modval) + "</p> ";
			}
			html += "</div>";
			var reactElement = Parser(html);
			return reactElement;

		} else if (comboLen > 0) {
			for (var i = 0, length = comboLen; i < length; i++) {
				var comboName = combo[i]['menu_component_name'];
				var comboVal = this.showComboProducts(combo[i]['product_details']);

				html += "<p><b>" + comboName + ":</b></p><p> " + comboVal + " " + this.showComboModifiers(combo[i]['product_details'][0]['modifiers']) + "</p> ";
			}
			html += "</div>";
			var reactElement = Parser(html);
			return reactElement;

		}
	}
	
	/* show combo products  list */
	showComboProducts(combos) {
		var lenCombo = combos.length;
		var html = " ";
		if (lenCombo > 0) {
			for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
				var comboPro = combos[r]['menu_product_name'];
				var comboQty = combos[r]['menu_product_qty'];
				var comboPrice = combos[r]['menu_product_price'];
				var newPrice = (comboPrice > 0) ? " (+" + comboPrice + ")" : "";
				html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
			}
			return html;
		}
		return "";
	}

	/* this function used to show combo modifieirs list */
	showComboModifiers(modifiers) {

		var lenMod = modifiers.length;
		var html = '<div >';
		if (lenMod > 0) {
			for (var i = 0, length = lenMod; i < length; i++) {
				var modName = modifiers[i]['order_modifier_name'];
				var modval = modifiers[i]['modifiers_values'][0]['order_modifier_name'];
				var modValPrice = modifiers[i]['modifiers_values'][0]['order_modifier_price'];
				var newModValPrice = (modValPrice > 0) ? " (+" + modValPrice + ")" : "";
				html += "<p><b>" + modName + ":</b></p><p> " + modval + newModValPrice + "</p> ";
			}
			html += '</div>';

			return html;

		}

		return "";
	}
	  
	  
	render() {
		  
		let orderArr = this.props.orderdetail;
		var orderDetail = [];
		var remarks = '';
		if(Object.keys(orderArr).length > 0) {
			if(orderArr[0].status === 'ok') {
				orderDetail = orderArr[0].result_set[0];
				var remarks = orderDetail.order_remarks !== ''?<div className="remark_notesec text-left"><h4>Remarks</h4><p>{orderDetail.order_remarks}</p></div>:'';				
			}
		}
		
		return (<div>
	
				{/* thankyou-main-div - start */}
				<div className="thankyou-main-div" >
				
				{/* Header start */}
				<Header />
				{/* Header End */}
				
				
				 {/* innersection_wrap - start */}
				 {(Object.keys(orderDetail).length > 0) && <div className="innersection_wrap">
				 <div className="container">
				 	<div className="tnkyou-pagediv">
						<div className="tnkyou-header">
							<img src={thnkyou_tick_img} />
							<h2>Thank You</h2>
							<p>Your order has been placed successfully</p>
						</div>
					 
					 	{/* order-detail-maindiv - start */} 
					 	<div className="thank-order-detaildiv">
					 		<div className="thank-order-top text-center">
							<h3>YOUR ORDER DETAILS</h3>
							<div className="tnk-order">
								<h3>Order No - {orderDetail.order_local_no}</h3>
								<p>Order placed at :  {Moment(orderDetail.order_created_on).format('DD-MM-YYYY hh : mm A')}<br></br>Pay by : {orderDetail.order_method_name}</p>
							</div>
					 	</div>
					 
					 	<div className="tnk-delivery">
					 		<div className="tnk-common-box">
								{(orderDetail.order_availability_id === deliveryId || orderDetail.order_availability_id === madbarId) ? <div className="thank-details-top">
								<div className="cart-direction">
								  <img className="cart-direction-left" src={scootyImg} />
								  <img className="cart-direction-right" src={cartMpImg} />
								</div>
								<div className="thank-detailsinner-top">
									<div className="thank-detailin-top-left">
										<h3>Order Handled By</h3>
										<p>{stripslashes(orderDetail.outlet_name)}</p>
										<p>{addressFormat(orderDetail.outlet_unit_number1, orderDetail.outlet_unit_number2, orderDetail.outlet_address_line1, orderDetail.outlet_address_line2, orderDetail.outlet_postal_code)}</p>
									</div>
									<div className="thank-detailin-top-right">
										<h3>Delivery Location</h3>
										<p>{addressFormat(orderDetail.order_customer_unit_no1, orderDetail.order_customer_unit_no2, orderDetail.order_customer_address_line1, orderDetail.order_customer_address_line2, orderDetail.order_customer_postal_code)}</p>   
									</div>
								</div>
								</div> : <div className="pickup-cart-div"><div className="cart-direction">
								  <img className="cart-direction-left" src={pickupImg} />
								</div>
									<div className="cart_row cart-header-first">
										<div className="row">
											<div className="col-xs-12 cart_left text-center">
												<h4>Pickup Location</h4>
												<p>{stripslashes(orderDetail.outlet_name)}</p>
												<p>{addressFormat(orderDetail.outlet_unit_number1, orderDetail.outlet_unit_number2, orderDetail.outlet_address_line1, orderDetail.outlet_address_line2, orderDetail.outlet_postal_code)}</p>
											</div>
										</div>
									</div>
								</div>}
							
								<div className="thank-details-middle">
									<div className="thank-details-middle-left">
										<h2>{(orderDetail.order_availability_id === deliveryId || orderDetail.order_availability_id === madbarId)?"Delivery":"Pickup"} Date</h2>
										<h4 className="checkoutDate">{Moment(orderDetail.order_date).format('DD-MM-YYYY')}</h4>
									</div>
									<div className="thank-details-middle-right">
										<h2>{(orderDetail.order_availability_id === deliveryId || orderDetail.order_availability_id === madbarId)?"Delivery":"Pickup"} time</h2>
										<h4 className="checkoutTime">{Moment(orderDetail.order_date).format('hh:mm A')}</h4>
									</div>
								</div>
							</div>
							<div className="hcart_tt">
								<h3>Your Items</h3>
							</div>
								
							<div className="tnkyou-product-div">
								{this.loadItems(orderDetail)}
							</div>
							<div className="orderitem_body_div">
                <div className="overall-parent">
                  {orderDetail.order_remarks !== "" && (
                    <div className="remark_notesec text-left">
                      <h4>Remarks</h4>
                      <p>{orderDetail.order_remarks}</p>
                    </div>
                  )}

                  {orderDetail.order_customer_send_gift === "Yes" && (
                    <>
                      <div className="remark_notesec text-left">
                        <h4>Recipient Name</h4>
                        <p>{orderDetail.order_recipient_name}</p>
                      </div>
                      <div className="remark_notesec text-left">
                        <h4>Recipient Contact Number</h4>
                        <p>{orderDetail.order_recipient_contact_no}</p>
                      </div>
                      {orderDetail.order_gift_message !== "" &&
                        orderDetail.order_gift_message !== null && (
                          <div className="remark_notesec text-left">
                            <h4>Gift Message</h4>
                            <p>{orderDetail.order_gift_message}</p>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
							<div className="tnk_cart_footer">
								<div className="cart_row">
									<p className="text-uppercase">SUBTOTAL</p>
									<span>${orderDetail.order_sub_total}</span>
								</div>
									
								{parseFloat(orderDetail.order_service_charge_amount) > 0 && <div className="cart_row">

									{(parseFloat(orderDetail.order_service_charge) > 0)?<p className="text-uppercase">Service Charge ({parseFloat(orderDetail.order_service_charge)}%)</p>:<p className="text-uppercase">Service Charge</p>}
						
									<span>${parseFloat(orderDetail.order_service_charge_amount).toFixed(2)}</span>

								</div>}
								
								{parseFloat(orderDetail.order_delivery_charge) > 0 && <div className="cart_row">

									<p className="text-uppercase">Delivery Charge</p>
									<span>${parseFloat(orderDetail.order_delivery_charge).toFixed(2)}</span>
								</div>}
								
								{parseFloat(orderDetail.order_additional_delivery) > 0 && <div className="cart_row">
									<p className="text-uppercase">Additional Delivery Charge</p>
									
									<span>${parseFloat(orderDetail.order_additional_delivery).toFixed(2)}</span>
								</div>}
								
								{parseFloat(orderDetail.order_subcharge_amount) > 0 && <div className="cart_row">
									<p className="text-uppercase">SurCharge</p>
									<span>${parseFloat(orderDetail.order_subcharge_amount).toFixed(2)}</span>
								</div>}
								
								{parseFloat(orderDetail.order_packaging_charge) > 0 && <div className="cart_row">
									<p className="text-uppercase">Packaging Charge</p>
									<span>${parseFloat(orderDetail.order_packaging_charge).toFixed(2)}</span>
								</div>}
								
								{parseFloat(orderDetail.order_discount_amount) > 0 && <div className="cart_row">

									<p className="text-uppercase">
										{orderDetail.order_discount_type === "redeem"
                              ? "Discount (-)"
                  	          : "Promocode (-)"}
                  </p>
									
									<span>${parseFloat(orderDetail.order_discount_amount).toFixed(2)}</span>
								</div>}
									
									{parseFloat(orderDetail.order_tax_calculate_amount) > 0 && <div className="cart_row">

									<p className="text-uppercase">GST( {parseFloat(orderDetail.order_tax_charge)}%)</p>
							
									<span>${parseFloat(orderDetail.order_tax_calculate_amount).toFixed(2)}</span>

								</div>}
								
								<div className="cart_row grant-total-cls">
									<p className="text-uppercase">Total</p>
									<span>${parseFloat(orderDetail.order_total_amount).toFixed(2)}</span>
								</div>		
							</div>
					 	</div>	
						 <div className="tnk-btnp-div">
							<Link to={"/myorders"} className="button">Check Order Status</Link>
						</div>
				
					</div>
					</div>
				   </div>{/* container - end */}
				 </div>}
				 {/* innersection_wrap - start */}
				 
				 
				
				</div>
			    {/* thankyou-main-div - end */}
				
				{/* Footer section */}
		        <Footer />
					
				</div>
			   )
	  }	
}

const mapStateTopProps = (state) => {
  return {
    orderdetail: state.orderdetail
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(Thankyou);
