/* eslint-disable */
import React, { Component } from 'react';

import { Link} from 'react-router-dom';

import Slider from "react-slick";
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { stripslashes, hideLoader, showAlert} from "../Helpers/SettingHelper";

import { appId, apiUrl, webUrl, apiUrlV2, timThumpUrl, reservationId } from "../Helpers/Config";


import Parser from 'html-react-parser';

import update from 'immutability-helper'

import { validated } from 'react-custom-validation'
import validator from 'validator'

import Axios from 'axios';
import cookie from 'react-cookies';

import Header from "../Layout/Header"
import Footer from "../Layout/Footer"

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import bannerRestImg from "../../common/images/banner-restaurants.jpg";

import { GET_PICKUP_OUTLETS, GET_GLOBAL_SETTINGS} from '../../actions';




class Outlets extends Component {

    constructor(props) {
        super(props);
		this.state = {outlets:[], globalsettings: [], starttime:'', endtime:'', image_source:""};
    }

    componentDidMount() {
    	cookie.remove('loginpopupTrigger', { path: "/" });
		$('#dvLoading').fadeIn();
           // this.props.getPickupOutlets();       
			this.props.getGlobalSettings();
            $('html, body').animate({ scrollTop: 0 }, 800);

			Axios.get(apiUrl + 'outlets/getAllOutles?app_id='+appId).then(res => {
				$('#dvLoading').fadeOut(2000);
				if (res.data.status === "ok") {
					
					this.setState({outlets: res.data.result_set});

				} else if (res.data.status === "error") {
						
				}
				
				return false;

			});
			/* var locations = [
				['The Poiz Centre, 51 Upper Serangoon Road, Singapore', 1.3314344,103.8662334, 4],
				['Flora Vista, 7 Ang Mo Kio Street 66, Singapore', 1.3917139,103.848384, 4],
				
			  ];
		  
			  var map = new google.maps.Map(document.getElementById('map'), {
				zoom: 11,
				center: new google.maps.LatLng(1.3917139,103.848384),
				mapTypeId: google.maps.MapTypeId.ROADMAP
			  });
		  
			  var infowindow = new google.maps.InfoWindow();
		  
			  var marker, i; 
		  
			  for (i = 0; i < locations.length; i++) {  
				marker = new google.maps.Marker({
				  position: new google.maps.LatLng(locations[i][1], locations[i][2]),
				  map: map
				});
		  
				google.maps.event.addListener(marker, 'click', (function(marker, i) {
				  return function() {
					infowindow.setContent(locations[i][0]);
					infowindow.open(map, marker);
				  }
				})(marker, i));
			  }*/
    }
    
    componentWillReceiveProps(nextProps) {


        /*if(nextProps.outlets !==this.props.outlets){
		   if(nextProps.outlets && nextProps.outlets[0].status == 'ok'){
				 $('#dvLoading').fadeOut(2000);
				 
                 this.setState({outlets: nextProps.outlets[0].result_set, image_source: nextProps.outlets[0].common.image_source});
              }
          }*/

	 if(nextProps.globalsettings !==this.props.globalsettings){
		   if(nextProps.globalsettings && nextProps.globalsettings[0].status == 'ok'){
		   		let client_start_time = (nextProps.globalsettings[0].result_set.client_start_time !== '') ? nextProps.globalsettings[0].result_set.client_start_time : '';
		   		let client_end_time = (nextProps.globalsettings[0].result_set.client_end_time !== '') ? nextProps.globalsettings[0].result_set.client_end_time : '';
                 this.setState({starttime: client_start_time,endtime: client_end_time});

              }
          }

        
	}
	
 tConvert (time) {
 	if(time !=='' && time !== undefined && time !== null){
	  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
	  if (time.length > 1) { 
	    time = time.slice (1);  
	    time[5] = +time[0] < 12 ? ' AM' : ' PM'; 
	    time[0] = +time[0] % 12 || 12; 
	  }
	  return time.join (''); 
	}
}

allInOnepopup() {
	/*$.magnificPopup.open({
	  items: {
		src: '#order-popup'
	  },
	  type: 'inline'
	});*/
	
	cookie.save("defaultAvilablityId",reservationId, { path: "/" });
	this.props.history.push('/reservation');
}

gotoContacus(outletId) {
	cookie.save("contusOutletId",outletId, { path: "/" });
	this.props.history.push('/contact-us');
}

callPhoneOptn(phoneTxt,type) {
	var resultTxt = '';
	if(type == 1){
		//for <a> tag
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = "tel:"+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "tel:+"+phoneTxt;
			} else {
				resultTxt = "tel:+65"+phoneTxt;
			}
		} else {
			resultTxt = 'javascript:void(0);';
		}
	}else{
		//for view
		if(phoneTxt !== '') {
			if(phoneTxt.indexOf("+65") !== -1) {
				resultTxt = ""+phoneTxt;
			} else if((phoneTxt.indexOf("65") !== -1) && (phoneTxt.length >= 10)) {
				resultTxt = "+"+phoneTxt.replace(' ','');
				resultTxt = resultTxt.replace('+65','+65 ');				
			} else {
				resultTxt = "+65 "+phoneTxt;
			}
			resultTxt = resultTxt.replace(/\d{4}(?=.)/g, '$& ');
		} else {
			resultTxt = '';
		}
	}
	
	return resultTxt;
}

timingCnt(outletId) {
	/* jankosoft 'Dempsey' => 190 'Suntec' => 191 */
	if(outletId === '313') {
		return (<span>Mon-Thurs: 11am-10pm <br></br>*last dine in order at 9.30pm <br></br>Fri-Sun: 10am-10pm <br></br>*last dine in order at 9.30pm</span>); }
	else if(outletId === '320') {
		return (<span>Mon-Sun: 11am-10pm <br></br>*last dine in order at 9.30pm</span>); }
	else if(outletId === '322') {
		return (<span>Mon-Sun: 11am-10pm <br></br>*last dine in order at 9.30pm</span>); }
	else if(outletId === '323') {
		return (<span>Mon-Sun: 12pm-8pm</span>);	
	}
	else {
		return (<span>Timing: {this.tConvert(this.state.starttime)} to {this.tConvert(this.state.endtime)}</span>);
	}
}

getOutletData = (dataProp, image_source) =>{
  
  if (dataProp) { 
	   return dataProp.map((item, index) =>{
		   console.log(item);
			   return (<li key={index} >
					   <div className="ourrest_row">
						   <div className="ourrest_info">
							   <h4>{stripslashes(item.outlet_name)}</h4>
							   <a href={"https://maps.google.com/maps?ll="+item.outlet_marker_latitude+","+item.outlet_marker_longitude+"&q="+encodeURIComponent(stripslashes(item.outlet_name))+"&hl=es;output=embed"} target="_blank"><i className="fa fa-fw  fa-map-marker"></i> {item.outlet_address_line1} {(item.outlet_unit_number2 !== '')?'#'+item.outlet_unit_number1+'-'+item.outlet_unit_number2:item.outlet_unit_number1}</a>
							   <p><a href={"https://maps.google.com/maps?ll="+item.outlet_marker_latitude+","+item.outlet_marker_longitude+"&q="+encodeURIComponent(stripslashes(item.outlet_name))+"&hl=es;output=embed"} target="_blank"> Singapore {item.outlet_postal_code} </a></p>
							   <p>
							   {(item.outlet_email !== '') && <span><i className="fa fa-fw fa-envelope"></i> Email: <a href={"mailto:"+item.outlet_email}> {item.outlet_email} </a> <br></br></span>}
							   {(item.outlet_phone !== '') && <div><i className="fa fa-fw fa-phone"></i>Tel: <span className="phone-show-dskp"> <a href={this.callPhoneOptn(item.outlet_phone,1)}> {this.callPhoneOptn(item.outlet_phone,2)} </a></span><br></br> </div>}
							   </p>
							   {this.state.starttime !=='' && this.state.starttime !== undefined && this.state.starttime !== null && 
							   		<p><i className="fa fa-clock-o"></i> {this.timingCnt(item.outlet_id)}</p>
							   }
						   </div>
						   <div className="ourrest_map">
						   <div id="map">
							   <img src={image_source+item.outlet_image} />
						   </div>
						   </div>
					   </div>
			   </li>);
		   })
  }        
}
	
	

 render() {
	var image_source = webUrl+"/media/dev_team/outlet/";

	   return (
	   		<div className="outlet-main-div">

					
					{/* Header start */}
					<Header />
					{/* Header End */}
				
					<div className="common-inner-blckdiv">
						<div className="page-banner">
							<img src={bannerRestImg} alt="" /> 
							<div className="inner-banner-content">
								<h2>Our Outlets</h2>
							</div>
						</div>
					</div>
					<div className="location-maindiv">
						<div className="container">
							<div className="location-indiv">
								<ul className="outletul">
									{this.getOutletData(this.state.outlets, image_source)}
								</ul>
							</div>
						</div>
					</div>
				
				 
			   <Footer />
			   <div id="dvLoading"></div>
	   		</div>);
 }
 
}



const mapStateToProps = (state) => {
return {
outlets: state.outlets,
globalsettings: state.settings,
}
}
const mapDispatchToProps = (dispatch) => {
return {
getPickupOutlets: () => {
dispatch({ type: GET_PICKUP_OUTLETS});
},
getGlobalSettings: () => {
dispatch({ type: GET_GLOBAL_SETTINGS });
},
}
}
Outlets.propTypes = {
history: PropTypes.shape({
push: PropTypes.func.isRequired
}) 
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Outlets));

 
