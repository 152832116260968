/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import { connect } from "react-redux";
import Slider from "react-slick";
import Axios from "axios";
import Parser from "html-react-parser";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import HomeBanner from "./HomeSubCompo/HomeBanner";
import FeaturedProducts from "./HomeSubCompo/FeaturedProducts";
import PromotionProducts from "./HomeSubCompo/PromotionProducts";
import MenuDNavigation from "./HomeSubCompo/MenuHomeNavigation";

import {
  appId,
  apiUrl,
  deliveryId,
  madbarId,
  pickupId,
  timThumpUrl,
  reservationId,
  cateringId,
} from "../Helpers/Config";

import { stripslashes } from "../Helpers/SettingHelper";

import {
  GET_STATIC_BLOCK,
  GET_MENU_NAVIGATION,
  GET_NORMAL_POPUP,
} from "../../actions";

//import joinusImg from "../../common/images/join-lhs.png";
import joinusIcon from "../../common/images/join.png";

import { callImage } from "../Helpers/SettingHelper";
import Testimonial from "./HomeSubCompo/Testimonial";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

class Home extends Component {
  constructor(props) {
    super(props);
    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }
    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      home_top_note: [],
      our_story: [],
      whatshappenings_source: "",
      whatshappenings: [],
      photoIndex: "",
      selectedNavigation: "",
      selectedNavigationFilter: "",
      selectedMainCatId:
        cookie.load("selectedMainCatId") !== "" &&
        cookie.load("selectedMainCatId") !== undefined &&
        cookie.load("selectedMainCatId") !== "undefined"
          ? cookie.load("selectedMainCatId")
          : "",
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
    };
    var outltIdTxt = cookie.load("orderOutletId");
    this.props.getMenuNavigationList(outltIdTxt);
    cookie.remove("popuptriggerFrom", { path: "/" });
    cookie.remove("selectedMainCat", { path: "/" });
    cookie.remove("selectedMainCatId", { path: "/" });
  }

  componentDidMount() {
    this.props.getStaticBlock();
    this.props.getNormalPopup();

    Axios.get(apiUrl + "cms/whats_happening?app_id=" + appId).then((res) => {
      if (res.data.status === "ok") {
        let whatshappenings_source = res.data?.common?.image_source;
        let whatshappenings = res.data.result_set;

        this.setState({
          whatshappenings_source: whatshappenings_source,
          whatshappenings: whatshappenings,
        });
      } else if (res.data.status === "error") {
      }

      return false;
    });

    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      console.log("orderPopuptrigger not remove");
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: ".order_popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "myaccount") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "myorders") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "myrewards") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "mypromotions") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "myvouchers") {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("cateringPay") === "initial") {
      cookie.save("cateringPay", "start");
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    /*setTimeout(function(){ if ($(".address-list-main").length > 0) {
		$(".address-list-main").mCustomScrollbar();
		$(".address-list-error").mCustomScrollbar();
		} }, 1000);*/
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      let home_top_note = "";
      let our_story = "";
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data, index) => {
          if (data.staticblocks_slug === "home-top-note") {
            home_top_note = data.staticblocks_description;
          }
          if (data.staticblocks_slug === "our-story") {
            our_story = data;
          }
        });
      }
      home_top_note =
        home_top_note !== "" ? Parser(home_top_note) : home_top_note;

      this.setState({ home_top_note: home_top_note, our_story: our_story });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      this.state.normalpopupdata &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function() {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          this.state.normalpopupdata &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
      var globalsettings = this.props.globalsettings;
      if (Object.keys(globalsettings).length > 0) {
        if (globalsettings[0].result_set.client_promocode_options === "1") {
          $.magnificPopup.open({
            items: {
              src: "#promo-check-popup",
            },
            type: "inline",
            midClick: true,
            closeOnBgClick: false,
          });
        }
      }
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function() {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
    if (field === "selectedMainCat") {
      this.setState({ selectedMainCat: value });
      cookie.save("selectedMainCat", value, { path: "/" });
    }

    if (field === "selectedMainCatId") {
      this.setState({ selectedMainCatId: value });
      cookie.save("selectedMainCatId", value, { path: "/" });
    }
  };

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      var singleOutltSlst = $("#delevery-popup").find(".self_outlet_inner li")
        .length;
      /*popupIdtxt = '#delevery-popup';*/
      popupIdtxt =
        singleOutltSlst === 1 ? "#delevery-postcode-popup" : "#delevery-popup";
    } else if (availability === madbarId) {
      //popupIdtxt = (singleOutltSlst === 1) ? '#delevery-postcode-popup' : '#delevery-popup';
      popupIdtxt = "#madbar-postcode-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId);
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId);
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  checkActiveDiv(avlType) {
    var clsTxt = "home-ordernow-single ";
    var availability = cookie.load("defaultAvilablityId");
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  listwhatshappenings() {
    let whatshappenings = this.state.whatshappenings;
    let whatshappenings_source = this.state.whatshappenings_source;

    if (Object.keys(whatshappenings).length > 0) {
      const mainMenu = whatshappenings.map((loaddata, index) => {
        if (loaddata.gallery_image.length <= 4) {
          var infinite = false;
        } else {
          var infinite = true;
        }

        let Promotions = {
          dots: true,
          arrows: false,
          infinite: infinite,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: true,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                adaptiveHeight: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
        };

        return (
          <div className="container-two" key={"wh" + (index + 1)}>
            <div className="our-promotions-section">
              <h1>{loaddata.wh_name}</h1>

              <Slider {...Promotions}>
                {loaddata.gallery_image.map((loaddatagall, gallindex) => {
                  return (
                    <div
                      key={"whgall" + index + "" + (gallindex + 1)}
                      onClick={() =>
                        this.setState({
                          photoIndex:
                            whatshappenings_source +
                            "/" +
                            loaddatagall.gallery_image,
                          isOpen: true,
                        })
                      }
                    >
                      {callImage(
                        whatshappenings_source,
                        loaddatagall.gallery_image,
                        315,
                        447,
                        timThumpUrl
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        );
      });

      return mainMenu;
    } else {
      return null;
    }
  }

  render() {
    const { isOpen, photoIndex } = this.state;
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "";
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div>
        {this.state.home_top_note}
        <div className="home-main-div">
          {/* Header section */}
          <Header
            homePageState={this.state}
            cartTriggerFlg={this.state.cartTriggerFlg}
            sateValChange={this.sateValChange}
          />

          {/* Home banner section */}
          <HomeBanner />

          {/* Home Category section - start */}

          <section className="home-category">
            <div className="container-full">
              <div className="home-category-inner">
                <MenuDNavigation
                  {...this.props}
                  productState={this.state}
                  sateValChange={this.sateValChange}
                />
              </div>
            </div>
          </section>

          {/* Home Category section - End */}

          <section className="home-feature pd-top-50">
            <div className="container">
              <FeaturedProducts
                {...this.props}
                sateValChange={this.sateValChange}
              />
            </div>
          </section>

          {/* Join Us section - start */}
          <section className="home-join pd-top-50">
            <div className="container-full">
              <div className="join-us-section">
                <div className="lft-sec-prt">
                  {/* <img src={joinusImg} /> */}
                </div>
                <div className="lft-sec-prt-main">
                  <div className="mid-sec-prt">
                    <h4>WELCOME TO</h4>
                    <h2>Your Online Liquor Store</h2>
                    <a
                      href="#signup-popup"
                      className="open-popup-link"
                      title="ORDER Now"
                    >
                      {" "}
                      <span>ORDER Now</span>
                    </a>
                  </div>
                  <div className="rgt-sec-prt">
                    <img src={joinusIcon} />
                    {/*<p>Earn Rewards, Enjoy Deals, Earn Promos <br/>& Have Lots Of Fun</p>*/}
                    {/* {!cookie.load("UserId") &&
										<a href="#signup-popup" className="open-popup-link" title="Apply Now"> <span>ORDER Now</span></a>
										}  */}
                    {/* <a href={"terms-conditions"} title="Member Benefits"><span>Member Benefits</span></a> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* Join Us section - End */}

          {/* About Us section - Start */}
          <section className="about-one pd-top-50">
            <div className="container">
              {Object.keys(this.state.our_story).length > 0 && (
                <div className="about-row">
                  <div className="about-left-container">
                    <div className="about-left-one">
                      {Parser(this.state.our_story.staticblocks_description)}
                    </div>
                  </div>
                  <div className="about-right-container">
                    {this.state.our_story.gallery_images.map(
                      (loaddatagall, gallindex) => {
                        return (
                          <div className="item" key={gallindex}>
                            <figure className="rounded shadow">
                              <img
                                src={
                                  this.state.our_story.gallery_image_path +
                                  loaddatagall
                                }
                              />
                            </figure>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              )}
            </div>
          </section>
          <section className="our-promotions pd-top-50">
            <div className="container">
              {/*<PromotionProducts sateValChange={this.sateValChange}  />*/}
              {this.listwhatshappenings()}
            </div>
          </section>
          <Testimonial />

          {isOpen && (
            <Lightbox
              mainSrc={photoIndex}
              onCloseRequest={() => this.setState({ isOpen: false })}
            />
          )}
          {/* Footer section */}
          <Footer />
          {/*Normal popup Start*/}
          <div
            id="normal-popup"
            className="white-popup mfp-hide popup_sec normal-popup"
          >
            <div className="normal_popup_alert">
              {this.state.normalpopup_status === "yes" && this.state.normalpopupdata &&
                Object.keys(this.state.normalpopupdata).length > 0 && (
                  <div>
                    <div className="full-login-new-header">
                      <h3>
                        {" "}
                        {stripslashes(
                          this.state.normalpopupdata.normalpopup_title
                        )}
                      </h3>
                    </div>
                    <div className="normal_popup_cont">
                      {Parser(
                        this.state.normalpopupdata.normalpopup_description
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          {/*Normal popup - End*/}
          {this.state.normalpopupstatus !== "initial" &&
            (showNormalPopup === "yes" || showPromoPopup === "yes") &&
            this.triggerNormalPopup("loading")}
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  if (Object.keys(state.productmenunav).length > 0) {
    var tempArr = !("menuNavigation" in state.productmenunav[0])
      ? Array()
      : state.productmenunav[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }
  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }
  return {
    staticblack: blacksArr,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
    globalsettings: state.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getMenuNavigationList: (outletID) => {
      dispatch({ type: GET_MENU_NAVIGATION, outletID });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
