import React from 'react';
import cookie from 'react-cookies';
import noimage from "../../common/images/noimg-800x800.jpg";
import { deliveryId, cateringId,currencySybmol,CountryTxt, mapcountry } from "./Config";
import $ from 'jquery'
/* stripslashes  */
export const stripslashes = function(str) { str = str.replace(/\\'/g, '\'');
    str = str.replace(/\\"/g, '"');
    str = str.replace(/\\0/g, '\0');
    str = str.replace(/\\\\/g, '\\');
    return str;
};

export const resetCrtStyle = function () {
  $(".cart_body").find(".cart_row").removeAttr("style");
};

export const capitalize = function (str) {
	return str.toLowerCase().replace( /(^|\s)([a-z])/g , function(m,p1,p2){ return p1+p2.toUpperCase(); } );
}

export const shorten = function (str, maxLen = '130', separator = ' ') {
  if (str.length <= maxLen) return str;
  return str.substr(0, str.lastIndexOf(separator, maxLen))+' ...';
}


/* Random ID  */
export const randomId = function() {
    var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

		for (var i = 0; i < 50; i++)
			text += possible.charAt(Math.floor(Math.random() * possible.length));

		return text;
};


/* Call image */
export const callImage = function(image_source,image,width,height,timthumb,imageTag = 'Yes') {
    
    var imagesource='';
        
        if(image!==null && image!==''){
            imagesource = timthumb+image_source+"/"+image+"&w="+width+"&h="+height+"&q=80";
            //imagesource = image_source+"/"+image;
        }else{
            imagesource = noimage;
        }
        if(imageTag === 'Yes'){
            return (<img src={imagesource} alt="" width={width} height={height} />);
        }else{
            return imagesource;
        }
    
};

/* sample funtion */
export const showSubTotalValue = function(price) { 

    price = (price !== '') ? parseFloat(price) : 0.00;
	var priceTxt = price.toFixed(2);
	return priceTxt;
	
 };

/*Reference ID Generate*/
export const getReferenceID = function() {

if (typeof cookie.load('referenceId') === 'undefined') {
    var randomKey = randomId();
    cookie.save('referenceId', randomKey, {}, { path: "/" });
    return randomKey;
}
else {

    return cookie.load('referenceId');

}

};

/*remove promotion value*/
export const removePromoCkValue = function() {

	cookie.remove('reedemPointVal', { path: "/" });       
	cookie.remove('promoCodeVal', { path: "/" });
	cookie.remove('promotionApplied', { path: "/" });
	cookie.remove('promotionType', { path: "/" });
	cookie.remove('promotionAmount', { path: "/" });
	cookie.remove('promotionSource', { path: "/" });       
	cookie.remove('promoIsDelivery', { path: "/" });
	cookie.remove('usedPoints', { path: "/" });

};

/*remove order date time*/
export const removeOrderDateTime = function() {

	cookie.remove('orderDateTime', { path: "/" });       
	cookie.remove('deliveryDate', { path: "/" });
	cookie.remove('deliveryTime', { path: "/" });

};

/*remove promotion value*/
export const getPromoCkValue = function() {

	var reedemPointVal = (typeof cookie.load('reedemPointVal') === 'undefined') ? '' : cookie.load('reedemPointVal');
	var promoCodeVal = (typeof cookie.load('promoCodeVal') === 'undefined') ? '' : cookie.load('promoCodeVal');
	var promotionApplied = (typeof cookie.load('promotionApplied') === 'undefined') ? '' : cookie.load('promotionApplied');
	var promotionType = (typeof cookie.load('promotionType') === 'undefined') ? '' : cookie.load('promotionType');
	var promotionAmount = (typeof cookie.load('promotionAmount') === 'undefined') ? '' : cookie.load('promotionAmount');
	var promotionSource = (typeof cookie.load('promotionSource') === 'undefined') ? '' : cookie.load('promotionSource');
	var promoIsDelivery = (typeof cookie.load('promoIsDelivery') === 'undefined') ? '' : cookie.load('promoIsDelivery');
	var usedPoints = (typeof cookie.load('usedPoints') === 'undefined') ? 0 : cookie.load('usedPoints');
	
	var resultArr = [];
		resultArr['reedemPointVal'] 		= reedemPointVal;
		resultArr['promoCodeVal'] 			= promoCodeVal;
		resultArr['promotionApplied'] 		= promotionApplied;
		resultArr['promotionType'] 			= promotionType;
		resultArr['promotionAmount'] 		= promotionAmount;
		resultArr['promotionSource'] 		= promotionSource;
		resultArr['promoIsDelivery'] 		= promoIsDelivery;
		resultArr['usedPoints'] 			= usedPoints;

	return resultArr;

};


/* address format */
export const addressFormat = function (unitOne, unitTwo, addressOne, addressTwo, postCode) {
	var unit = (unitTwo !== "" && unitOne !== "" ? "#" + unitOne + "-" + unitTwo + ", " : (unitOne !== '' ? "#" + unitOne + "," : ""));
	 unit = (addressOne !== "" ? unit + addressOne + ", " : unit );
	 unit = (addressTwo !== "" ? unit + addressTwo + ", " : unit );
	 unit = (postCode !== "" ? unit+'Singapore ' + postCode  : unit );

	return (unit !== "" ? unit.replace(/,\s*$/, "") : 'N/A' );
};

/* delivery charge */
export const getCalculatedAmount = function (settingsArr, zoneDetailsArr, cartDetailsArr, promoTionArr, serviceSubChrArr=Array()) {
	var deliveryAmount = 0;
	var additionalDelivery = 0;
	var freeDeliveryAmnt = 0;
	var orderDisplayGst = 0;
	var orderGstAmount = 0;
	var cartSubTotal = 0;
	var servicechrgType = '';
	var servicechrgDisplaylabel = '';
	var servicechargePer = 0;
	var serviceChrgAmt = 0;
	var surChargeAmt = 0;
	var promotionAmount = 0;
	var grandTotalAmount = 0;
	var packagingChargePer = 0;
	var packagingCharge = 0;
	var promotionApplied = '';
	var promotionTypeTxt = '';
    var voucherDiscountAmount = 0;

	if(Object.keys(settingsArr).length > 0) {
		/*deliveryAmount = (settingsArr.client_delivery_surcharge !== '') ? parseFloat(settingsArr.client_delivery_surcharge) : 0;
		additionalDelivery = (settingsArr.additional_delivery_charge !== '') ? parseFloat(settingsArr.additional_delivery_charge) : 0;
		freeDeliveryAmnt = (settingsArr.client_free_delivery_amount !== '') ? parseFloat(settingsArr.client_free_delivery_amount) : 0;*/
		orderDisplayGst = (settingsArr.client_tax_surcharge !== '') ? parseFloat(settingsArr.client_tax_surcharge) : 0;
		packagingChargePer = (settingsArr.client_packaging_charge !== '') ? parseFloat(settingsArr.client_packaging_charge) : 0;
		
	}
	
	if(Object.keys(zoneDetailsArr).length > 0) {
		deliveryAmount = (zoneDetailsArr[0].zone_delivery_charge !== '') ? parseFloat(zoneDetailsArr[0].zone_delivery_charge) : 0;
		additionalDelivery = (zoneDetailsArr[0].zone_additional_delivery_charge !== '') ? parseFloat(zoneDetailsArr[0].zone_additional_delivery_charge) : 0;
		freeDeliveryAmnt = (zoneDetailsArr[0].zone_free_delivery !== '') ? parseFloat(zoneDetailsArr[0].zone_free_delivery) : 0;
	}
	
	if(Object.keys(cartDetailsArr).length > 0) {
		cartSubTotal = parseFloat(cartDetailsArr.cart_sub_total);
	}

   if (Object.keys(cartDetailsArr).length > 0) {
    if (parseFloat(cartDetailsArr.cart_voucher_discount_amount) > 0) {
      voucherDiscountAmount = parseFloat(
        cartDetailsArr.cart_voucher_discount_amount
      );
    } else {
      voucherDiscountAmount = 0;
    }
   }
	
	if(Object.keys(serviceSubChrArr).length > 0) {
		
		    /*servicechargePer = (serviceSubChrArr['servicechrg_per'] !== '') ? parseFloat(serviceSubChrArr['servicechrg_per']) : 0;
		var servicechrgAmunt = (serviceSubChrArr['servicechrg_amount'] !== '')?parseFloat(serviceSubChrArr['servicechrg_amount']):0;

	    if(servicechargePer > 0 && cartSubTotal > 0) {
		    servicechrgAmunt  = (servicechargePer /100 ) * cartSubTotal;
	    }
		
		serviceChrgAmt = servicechrgAmunt;
		servicechrgType = serviceSubChrArr['servicechrg_type'];
		servicechrgDisplaylabel = serviceSubChrArr['servicechrg_displaylabel'];*/
		surChargeAmt = (serviceSubChrArr['surcharge_amount'] !== '') ? parseFloat(serviceSubChrArr['surcharge_amount']) : 0;
	}
	
	if((freeDeliveryAmnt>0) && (freeDeliveryAmnt <= cartSubTotal)) {
		deliveryAmount = 0;
	}
	
	if(promoTionArr['promotionApplied'] === 'Yes') {
		if(promoTionArr['promoIsDelivery'] === 'Yes') {
			deliveryAmount = 0;
		} else {
			promotionAmount = promoTionArr['promotionAmount'];
		}
	}
	
	if(cookie.load('defaultAvilablityId') !== deliveryId) {
		deliveryAmount = 0;
		additionalDelivery = 0;
	}
	
	if(packagingChargePer > 0) {
			packagingCharge = (cartSubTotal * (packagingChargePer / 100));	
    }
	
	grandTotalAmount = (cartSubTotal + deliveryAmount + additionalDelivery + serviceChrgAmt + surChargeAmt + packagingCharge) - promotionAmount;
	
	if(orderDisplayGst > 0) {
		orderGstAmount  = (orderDisplayGst / 100) * grandTotalAmount; 
	}


   if (voucherDiscountAmount > 0) {
    grandTotalAmount = grandTotalAmount - voucherDiscountAmount;
    if (grandTotalAmount > 0) {
      grandTotalAmount = grandTotalAmount;
    } else {
      grandTotalAmount = "0.00";
    }
   }
	
	grandTotalAmount = parseFloat(grandTotalAmount) + parseFloat(orderGstAmount);
	
	var resultArr = [];
	    resultArr['servicechrgType'] 	    = servicechrgType;
		resultArr['servicechrgDisplaylbl'] 	= servicechrgDisplaylabel;
		resultArr['servicechargePer'] 	    = servicechargePer;
		resultArr['serviceCharge'] 			= serviceChrgAmt.toFixed(2);
		resultArr['surCharge'] 				= surChargeAmt;
		resultArr['deliveryCharge'] 		= deliveryAmount;
		resultArr['additionalDelivery'] 	= additionalDelivery;
		resultArr['freeDeliveryAmnt'] 		= freeDeliveryAmnt;
		resultArr['promotionApplied'] 		= promotionApplied;
		resultArr['promotionTypeTxt'] 		= promotionTypeTxt;
		resultArr['promotionAmount'] 		= promotionAmount;
		resultArr['packagingChargePer'] 	= packagingChargePer;
		resultArr['packagingCharge'] 		= packagingCharge.toFixed(2);
		resultArr['orderDisplayGst'] 		= orderDisplayGst;
		resultArr['orderGstAmount'] 		= orderGstAmount.toFixed(2);
		resultArr['cartSubTotalAmount'] 	= cartSubTotal.toFixed(2);
		resultArr["voucherDiscountAmount"] = voucherDiscountAmount.toFixed(2);
		resultArr['grandTotalAmount'] 		= grandTotalAmount.toFixed(2);
	

	return resultArr;
};

/* int to str funtion */
export const cnvrtStr = function(d) { 

	return (d < 10) ? '0' + d.toString() : d.toString();
	
};

/* show Alert */
export const showAlert = function(header, message, autoClose = 'No') {
    $(".alert_popup").remove();
	if(autoClose === 'No') {
		$('body').append('<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+message+'</p><div class="alt_btns"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>');
	} else {
		$('body').append('<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">'+header+'</div><div class="alert_body"><p>'+message+'</p><div class="alt_btns"></div></div></div></div></div></div>');
		setTimeout(function () {
			autoClose.close();
		}, 1800);
	}
};


/* smooth Scroll */
export const smoothScroll = function(limit,value) {

	var limitTxt = (limit !== '') ? parseInt(limit) : 0;
	var valueTxt = (value !== '') ? parseInt(value) : 0;
	
	var stickyTop = $(window).scrollTop();
    if(stickyTop > limitTxt) {
	   var i = 10;
	   var int = setInterval(function() {
		window.scrollTo(0, i);
		i += 10;
		if (i >= valueTxt) clearInterval(int);
	   }, 20);
    }
    
};

/* show Custom Alert */
export const showCustomAlert = function(type,message) {

	var clsIdTxt = (type === 'success') ? "jquery-success-msg" : "jquery-error-msg";
	$(".custom_alertcls").hide();
	$("."+clsIdTxt).html(message);
	$("#"+clsIdTxt).fadeIn();
    setTimeout(function()
	{
		$(".custom_alertcls").hide();
	}, 6000);
    
};

/* show Custom Center Alert */
export const showCustomCenterAlert = function(type,message) {

	var clsIdTxt = (type === 'success') ? "jquery-common-success-msg" : "jquery-common-error-msg";
	$(".custom_center_alertcls").hide();
	$("."+clsIdTxt).html(message);
	$("#"+clsIdTxt).fadeIn();
    setTimeout(function()
	{
		$(".custom_center_alertcls").hide();
	}, 6000);
    
};

/* show Cart Count */
export const showCartItemCount = function(cartDetail) {

	var itemCount = (Object.keys(cartDetail).length > 0) ? cartDetail.cart_total_items : 0;
	var subTotal = (Object.keys(cartDetail).length > 0) ? cartDetail.cart_sub_total : 0;
	cookie.save("cartTotalItems",itemCount, { path: "/" });	
	$(".hcart_round").html(itemCount);
	if(parseFloat(subTotal)>0) {
		$(".crttotl_amt").show();
	} else {
		$(".crttotl_amt").hide();
	}
	var subTotalHtml = '$'+subTotal;
	$(".crttotl_amt").html(subTotalHtml);
    
};

/* show Loader */
export const showLoader = function(divRef,type) {
	if(type === 'class') {
		$('.'+divRef).addClass("loader-main-cls").append('<div class="loader-sub-div"></div>');
	} else {
		$('#'+divRef).addClass("loader-main-cls").append('<div class="loader-sub-div"></div>');
	}
};

/* hide Loader */
export const hideLoader = function(divRef,type) {
	if(type === 'class') {
		$('.'+divRef).removeClass("loader-main-cls");
		$('.'+divRef).find(".loader-sub-div").remove();
	} else {
		$('#'+divRef).removeClass("loader-main-cls");
		$('#'+divRef).find(".loader-sub-div").remove();
	}
};


 /* get subtotal value  */
 export const getsubTotal = function(subTotal,OriginalAmount,promotionApplied,redeemptionApplied=null) {
	
    if(promotionApplied === "Yes"){
         return subTotal;
    }else if(redeemptionApplied === "Yes"){
         return subTotal;
    }else{
         return OriginalAmount;
    }
  
};


/* get subtotal value  */
export const getDeliveryCharge = function(promotionApplied,deliveryEnabled,OriginalAmount,isFreeDelivery=null) {
  
    if( (promotionApplied === "Yes" && deliveryEnabled === "Yes") || isFreeDelivery === "Yes"){
         return 0;
    }else{
         return OriginalAmount;
    }
  
};

/* sample funtion */
export const showPriceValue = function(price) { 

    price = (price !== '') ? parseFloat(price) : 0.00;
	var priceTxt = '$ '+price.toFixed(2);
	return priceTxt;
	
 };

export const showPriceValueNew = function (price) {
  price = price !== "" ? parseFloat(price) : 0.0;
  var priceTxt = currencySybmol + price.toFixed(2);
  return priceTxt;
};

/* sample funtion */
export const getGstValue = function(gst,subtotal,format) { 

    gst = parseFloat(gst);
    subtotal = parseFloat(subtotal);
	var gstResult = 0;
   
    if(gst > 0) {
		gstResult  = (gst /100 ) * subtotal; 
	} 
	
	 if(format === "format"){
		  return gstResult.toFixed(2);
	 }else{
		  return gstResult;
	 } 

 };

 /* surcharge splitup */
export const getSurchargesplitup = function (surcharge, surlog) {
  let sur_deliverysetup_fee = 0;
  let sur_ear_deliverysetup_fee = 0;
  let sur_lat_teardown_fee = 0;

  let sur_deliverysetup_fee_name = "";
  let sur_ear_deliverysetup_fee_name = "";

  if (surcharge > 0) {
    sur_deliverysetup_fee = parseFloat(surlog.OrderPax.charge);
    sur_deliverysetup_fee += parseFloat(surlog.OrderAmount.charge);
    sur_deliverysetup_fee += parseFloat(surlog.Surcharge.charge);

    /*Pax value*/
    if (surlog.OrderPax.conditions.catering.amount_range_from !== undefined) {
      sur_deliverysetup_fee_name =
        "(" +
        surlog.OrderPax.conditions.catering.amount_range_from +
        " pax - " +
        surlog.OrderPax.conditions.catering.amount_range_to +
        " pax)";
    }

    /*Surchare based on date,time and day settings*/
    let sur_ear_deliverysetup_fee_time = 0;

    if (surlog.Surcharge.conditions !== undefined) {
      let basetimeArr = surlog.Surcharge.conditions.filter(
        (condobj) => condobj.surchage_based_on === "Time"
      );

      basetimeArr.forEach((item, i) => {
        sur_ear_deliverysetup_fee_time += parseFloat(item.price_value);

        sur_ear_deliverysetup_fee_name =
          "(" + item.subchr_from_time + " - " + item.subchr_to_time + ")";
      });

      if (basetimeArr.length !== 1) {
        sur_ear_deliverysetup_fee_name = "";
      }

      sur_deliverysetup_fee =
        sur_deliverysetup_fee - sur_ear_deliverysetup_fee_time;
      sur_ear_deliverysetup_fee = sur_ear_deliverysetup_fee_time;
    }

    sur_lat_teardown_fee = surlog.After9pm.charge;
  }

  return {
    sur_deliverysetup_fee: sur_deliverysetup_fee.toFixed(2),
    sur_ear_deliverysetup_fee: sur_ear_deliverysetup_fee.toFixed(2),
    sur_lat_teardown_fee: sur_lat_teardown_fee.toFixed(2),
    sur_deliverysetup_fee_name: sur_deliverysetup_fee_name,
    sur_ear_deliverysetup_fee_name: sur_ear_deliverysetup_fee_name,
  };
};
 
  /* GST Reverse Calculation funtion */
 export const getReverseGST = function(total) { 
    var vatDivisor = 1 + (7/100);
    var gstpercentage = 7/100;
    var productvalue = total/vatDivisor
    var gst = productvalue*gstpercentage;
    return "GST Inclusive (7%): $"+gst.toFixed(2);
  };  


 /* time conversion  */
 export const timeToConv12 = function(time24) {
	  var ts = time24;
	  if(ts !== "" && typeof ts !== 'undefined') {
		  var H = +ts.substr(0, 2);
		  var h = (H % 12) || 12;
		  h = (h < 10)?("0"+h):h;  
		  var ampm = H < 12 ? " AM" : " PM";
		  ts = h + ts.substr(2, 3) + ampm;
	  }
	  return ts;
  };
  
 /* Date conversion  */
 export const getOrderDateTime = function(dateTxt,TatTxt) {
	  var dateStr = new Date();
	  var TatTxtVl = (TatTxt !== '' && typeof TatTxt !== 'undefined') ? parseInt(TatTxt) : 0;
	  var deliveryTime = (typeof cookie.load('deliveryTime') === 'undefined') ? '' : cookie.load('deliveryTime');
	  if(dateTxt !== '' && typeof dateTxt !== 'undefined' && deliveryTime !== '') {
			  dateTxt = dateTxt.replace(/\"/g, "")
			  var dateTxtArr = dateTxt.split('T');
		      var selectTmArr = deliveryTime.split(':');
			  var seletedDate = new Date(dateTxtArr[0]);
		      seletedDate.setHours(parseInt(selectTmArr[0]));
		      seletedDate.setMinutes(parseInt(selectTmArr[1]));
		      seletedDate.setSeconds(0);
		      dateStr = seletedDate;
	  }	else {
		  var CurrentDate = new Date();
		      CurrentDate.setMinutes(CurrentDate.getMinutes() + TatTxtVl);
			  dateStr = CurrentDate;	  
	  }

	  return dateStr;
  }; 

 /* Date conversion  */
 export const dateConvFun = function(dateTxt,type) {
	  
	  var dateStr = dateTxt;
	  if(dateStr !== '' && typeof dateStr !== 'undefined') {
		  var newDateTxtone = dateTxt.replace(/-/g, '/');
		  var todayTime = new Date(newDateTxtone);
		  var month = (todayTime.getMonth() + 1);
		  month = (month > 9) ? month : '0'+month;
		  var day = todayTime.getDate();
		  day = (day > 9) ? day : '0'+day;
		  var year = todayTime.getFullYear();
		 
		  if(type === 1) {
			  dateStr = day + "/" + month + "/" + year;
		  } else if(type === 2) {
			  dateStr = day + "-" + month + "-" + year;
		  }
	  }
	  
	  return dateStr;
  }; 

  /* Date conversion  */
 export const getTimeFrmDate = function(timeTxt,type) {
	  
	  var timeStr = timeTxt;
	  if(timeStr !== '' && typeof timeStr !== 'undefined') {
		  var newtimeStr = timeStr.replace(/-/g, '/');
		  var todayTime = new Date(newtimeStr);
		  var hours = todayTime.getHours();
		  var minut = todayTime.getMinutes();
		  
		  hours = (parseInt(hours)<10) ? '0'+hours : hours; 
		  minut = (parseInt(minut)<10) ? '0'+minut : minut; 
		 
		  if(type === 1) {
			 timeStr = hours+" : "+minut;
		  } else if(type === 2) {
			 timeStr = hours+":"+minut;
			 timeStr = timeToConv12(timeStr);
		  }
	  }
	  
	  return timeStr;
  };
  
  /* Date conversion  */
 export const getCurrentDateTm = function() {
	  
	  var dateTimeStr = '';
	  var todayTime = new Date();
	  
	  var month = (todayTime.getMonth() + 1);
		  month = (month > 9) ? month : '0'+month;
	  var day = todayTime.getDate();
	      day = (day > 9) ? day : '0'+day;
	  var year = todayTime.getFullYear();
	  
	  var hours = todayTime.getHours();
	  var minut = todayTime.getMinutes();
	  var second = todayTime.getSeconds();
		  
		  hours = (parseInt(hours)<10) ? '0'+hours : hours; 
		  minut = (parseInt(minut)<10) ? '0'+minut : minut; 
		  second = (parseInt(minut)<10) ? '0'+second : second; 
     
      dateTimeStr = year+"-"+month+"-"+day+" "+hours+":"+minut+":"+second;	 
		
	  return dateTimeStr;
  };
  

 /* sample funtion */
 export const showCartLst = function() { 
	   setTimeout(function(){
			$(".hcart_dropdown").toggleClass('open');
			$(".hcartdd_trigger").toggleClass('active');
		},1000);
 };
  
/* sample funtion */
 export const getAliasName = function(alias,productName) { 

    return (alias !==""? alias : productName)
  };

/* Uc first funtion */
export const jsUcfirstFun = function(string) { 

    if(string !== '') {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return '';
	}
	
};  


export const numariconly = function(e) {
	const re = /[0-9. ]+/g;
  	if (!re.test(e.key)) {
    	e.preventDefault();
  	}
}

export const createScriptForAddToCart = function () {
  var element1 = document.getElementById("addtocarttag");
  if (element1 !== null) {
    element1.parentNode.removeChild(element1);
  }

  const script3 = document.createElement("script");
  script3.type = "text/javascript";
  script3.async = true;
  script3.id = "addtocarttag";
  script3.innerHTML =
    " function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-10862588258/K6P_CMrc9LYDEOLi17so', 'event_callback': callback }); return false; } ";
  document.head.appendChild(script3);
};
