/* eslint-disable */
import React, { Component } from "react";
import {
  stripslashes,
  showAlert,
  showLoader,
  hideLoader,
  showCustomAlert,
} from "../Helpers/SettingHelper";
import Slider from "react-slick";
import { baseUrl, appId, apiUrl } from "../Helpers/Config";
import cookie from "react-cookies";

import Parser from "html-react-parser";
import axios from "axios";

/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import Sidebar from "./Sidebar";
import { connect } from "react-redux";

var qs = require("qs");

import {
  GET_CART_DETAIL,
  GET_PROMOTIONLIST,
  GET_PROMOTIONRECEIPT,
  GET_APPLYPROMOTION,
  GET_ACTIVITYCOUNT,
} from "../../actions";

import promotionImage from "../../common/images/promo-placeholder.jpg";
import proVouchar from "../../common/images/pro-voucher.png";
import cashVouchar from "../../common/images/cash-voucher.png";
import BigproVouchar from "../../common/images/big-pro-vouchar.png";
import BigcashVouchar from "../../common/images/big-cash-vouchar.png";

class Myvoucher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoused: [],
      promotions: [],
      status: "",
      promo: [],
      cartItems: [],
      promodetails: [],
      cartDetails: [],
      cart_sub_total: 0,
      cartStatus: "",
      order_all: 0,
      overall_orders: 0,
      slickGoTo: 3,

      voucherStatus: "Waiting",
      myVouchers: [],
      usedVouchers: [],
      productimagePath: "",
      currentDate: "",
      redirectToCart: false,
      cartTotalItmCount: 0,
      cartTriggerFlg: "no",
    };

    if (cookie.load("UserId") == "" || cookie.load("UserId") == undefined) {
      cookie.save("loginpopupTrigger", 'myvouchers', { path: "/" });
      props.history.push("/");
    }
  }

  componentDidMount() {
    this.getActivityCounts();
    var customerParam = "&customer_id=" + cookie.load("UserId");
    this.props.getPromotionList(customerParam);
    $("#dvLoading").fadeOut(2000);

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    this.setState({
      currentDate: today,
    });

    axios
      .get(
        apiUrl +
          "promotion_api_v2/vouchers?app_id=" +
          appId +
          "&customer_id=" +
          cookie.load("UserId")
      )
      .then((vouchers) => {
        this.setState({ voucherStatus: "Ready" });
        /* set promotion content */
        if (vouchers.data.status === "ok") {
          this.setState({
            myVouchers: vouchers.data.result_set.voucher_list,
            usedVouchers: vouchers.data.result_set.used_vouchers,
            productimagePath: vouchers.data.common.image_source,
            status: "ok",
          });
        }
      });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.promotionlist !== this.props.promotionlist) {
      if (nextProps.promotionlist[0].status === "ok") {
        this.setState({ status: nextProps.promotionlist[0].status });
        this.setState({
          promotions: nextProps.promotionlist[0].result_set.my_promo,
        });
        this.setState({
          promoused: nextProps.promotionlist[0].result_set.promo_history,
        });
      }
    }

    /* for receipt promotion */
    if (nextProps.promotionreceipt !== this.props.promotionreceipt) {
      if (nextProps.promotionreceipt[0].status === "ok") {
        this.setState({
          promodetails: nextProps.promotionreceipt[0].result_set,
        });

        $("#dvLoading").fadeOut(2000);

        $.magnificPopup.open({
          items: {
            src: ".receipt_popup",
          },
          type: "inline",
        });
      } else {
        this.setState({ promodetails: [] });
      }
    }

    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
          });
        }
      }
    } else {
      this.setState({ overall_orders: 0 });
    }
    /*activity count - end*/
  }

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  /*  get promo category details  */
  getProductIdsDet(cartItems) {
    var product_cartid = "";
    if (Object.keys(cartItems).length > 0) {
      for (var key in cartItems) {
        if (
          product_cartid !== "" &&
          cartItems[key].cart_item_product_id !== ""
        ) {
          product_cartid += ";";
        }
        product_cartid +=
          cartItems[key].cart_item_product_id +
          "|" +
          cartItems[key].cart_item_total_price +
          "|" +
          cartItems[key].cart_item_qty;
      }
    }

    return product_cartid;
  }

  applyCouponInput() {
    var promocode = $("#applyPromo").val();
    this.applyCoupon("top", promocode);
  }

  addToCart(voucher) {
    if (voucher !== "") {
      if (voucher.product_voucher == "f") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var ImagePath = voucher.product_thumbnail;
        if (ImagePath !== "") {
          var postImagePath =
            this.state.productimagePath + voucher.product_thumbnail;
        } else {
          postImagePath = "";
        }

        var qty_voucher = $(".proqty_input").val();

        var postObject = {
          app_id: appId,
          product_id: voucher.item_product_id,
          product_name: voucher.item_name,
          product_sku: voucher.item_sku,
          product_image: postImagePath,
          availability_id: cookie.load("defaultAvilablityId"),
          product_unit_price: price,
          product_qty: qty_voucher,
          product_total_price: price,
          modifiers: modifier,
          menu_set_component: combo,
          customer_id: cookie.load("UserId"),
          order_availability_id: voucher.order_availability_id,
          order_item_id: voucher.order_item_id,
          order_outlet_id: voucher.order_outlet_id,
        };

        axios
          .post(apiUrl + "cart/is_voucher_insert", qs.stringify(postObject))
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              this.setState({ redirectToCart: true, cartTriggerFlg: "yes" });
              $(".mfp-close").trigger("click");
              // this.sateValChange("cartflg", "yes");
              showCustomAlert(
                "success",
                "Great choice! Voucher added to your cart."
              );
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Great choice! Voucher added to your cart."
              );
              this.props.history.push("/refpage/myvouchers");
            } else if (res.data.status === "error") {
              $(".mfp-close").trigger("click");
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }

      if (voucher.product_voucher == "c") {
        var modifier = [];
        var combo = [];
        var postObject = {};
        var price = parseFloat("0.00");
        var qty_voucher = $(".proqty_input").val();
        postObject = {
          app_id: appId,
          product_qty: qty_voucher,
          product_voucher_points: voucher.product_voucher_points,
          customer_id: cookie.load("UserId"),
          order_item_id: voucher.order_item_id,
        };

        axios
          .post(
            apiUrl + "ordersv1/add_voucher_redeem",
            qs.stringify(postObject)
          )
          .then((res) => {
            this.setState({ status: "ok" });
            if (res.data.status === "ok") {
              //push("/rewards");
              showCustomAlert("success", "Cash Voucher Points Credited.");
              /*showCartLst();*/
              this.handleShowAlertFunct(
                "success",
                "Cash Voucher Points Credited."
              );

              this.props.history.push("/rewards");
            } else if (res.data.status === "error") {
              this.handleShowAlertFunct("Error", res.data.message);
            }
          });
      }
    }
  }

  handleShowAlertFunct(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /*get receipt for promotion */
  getReceipt(promotion_id, promo) {
    $("#dvLoading").fadeIn();
    this.setState({ promo: promo });
    var params = "&promotion_id=" + promotion_id;
    this.props.getPromotionReceipt(params);
  }

  promotioncheckout() {
    $.magnificPopup.close();
    const { history } = this.props;
    cookie.save("fromCkeckOutVld", "Yes");
    history.push("/checkout");
  }

  promoPopup(promoData) {
    var promoImage =
      promoData.product_thumbnail !== "" && promoData.product_thumbnail !== null
        ? promoData.product_thumbnail
        : proVouchar;
    this.setState(
      { promodetails: promoData, promoImage: promoImage },
      function () {
        this.trgPromoPopup();
      }.bind(this)
    );
  }

  trgPromoPopup() {
    $.magnificPopup.open({
      items: {
        src: ".receipt_popup",
      },
      type: "inline",
    });
  }

  loadProductDate(date) {
    var product_date = new Date(date).toString().split(" ");
    return product_date[2] + "-" + product_date[1] + "-" + product_date[3];
  }

  loadMyVouchers() {
    var myVouchers =
      this.state.myVouchers !== undefined ? this.state.myVouchers : Array();

    if (Object.keys(myVouchers).length > 0) {
      return this.state.myVouchers.map((vouch, index) => (
        <li className="promo-earned-col" key={index}>
          <div className="promo-earned-col-item">
            <div className="promo-earned-col-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}
            </div>
            <div className="promo-earned-info">
              <div className="promo-earned-top">
                <h4 className="promo-earned-code">
                  <span> {vouch.item_name}</span>
                </h4>
                <span>Expiry {this.loadProductDate(vouch.expiry_date)}</span>
                <p className="vouchar-txt">{vouch.product_short_description}</p>
              </div>
              <div className="vouchar_btn" id={"promo-codediv-" + index}>
                {this.state.currentDate > vouch.expiry_date ? (
                  <a
                    className="button voacher_btn-emty"
                    href={void 0}
                  >
                    Expired{" "}
                  </a>
                ) : (
                  <a
                    className="button voacher_btn-one"
                    onClick={this.promoPopup.bind(this, vouch)}
                    href={void 0}
                  >
                    View & Redeem
                  </a>
                )}
              </div>
            </div>
          </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  /* Vouchers used list*/
  loadMyUsedVouchers() {
    var myVouchers =
      this.state.usedVouchers !== undefined ? this.state.usedVouchers : Array();

    if (Object.keys(myVouchers).length > 0) {
      return this.state.usedVouchers.map((vouch, index) => (
        <li className="promo-earned-col" key={index}>
          <div className="promo-earned-col-item">
            <div className="promo-earned-col-image">
              {vouch.product_thumbnail !== "" &&
              vouch.product_thumbnail !== null ? (
                <img
                  src={this.state.productimagePath + vouch.product_thumbnail}
                />
              ) : (
                <img
                  src={vouch.product_voucher == "c" ? cashVouchar : proVouchar}
                />
              )}
            </div>
            <div className="promo-earned-info">
              <div className="promo-earned-top">
                <h4 className="promo-earned-code">
                  <span> {vouch.item_name}</span>
                </h4>
                <span>Expiry {this.loadProductDate(vouch.expiry_date)}</span>
                <p className="vouchar-txt">{vouch.product_short_description}</p>
              </div>
              <div className="vouvherredmd_btn" id={"promo-codediv-" + index}>
                <a
                  className="button promation_btn-one"
                  href={void 0}
                >
                  Expired{" "}
                </a>
              </div>
            </div>
          </div>
        </li>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }

  proQtyAction(indxFlg, actionFlg, maxCount) {
    var proqtyInput = $(".proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    var AvailableQty = maxCount;
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      if (AvailableQty > proqtyInput) {
        proqtyInput = proqtyInput + 1;
      }
    }
    $(".proqty_input").val(proqtyInput);
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };

  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 5,
      slidesToScroll: 5,
      initialSlide: 4,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };
    return (
      <div className="myacc-main-div">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>My Vouchers</h3>
            <p className="acc-para">
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
          </div>
        </div>
        <div className="innersection_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container-full">
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="voucher_mainsec_div">
                    <Sidebar pageName="myvouchers" />
                    <div className="vouchersec_right">
                      <div className="vouchersec_inner">
                        <div className="voucher_fullwid">
                        <div className="voucher_boxfull">
                        <div className="congrats">
                              <div className="congrats-inner">
                                <h3>Redeem Your</h3>
                                <h2>Vouchers</h2>
                              </div>
                            </div>
                          <div className="tab_sec main_tabsec_inner">
                            <div className="myacc_filter">
                              <div
                                className="tab_sec filter_tabsec"
                                id="ordertab3"
                              >
                                <ul className="nav nav-tabs text-center">
                                  <li className="active">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn5"
                                      aria-expanded="true"
                                    >
                                      <span>Available Voucher</span>
                                    </a>
                                  </li>
                                  <li className="">
                                    <a
                                      data-toggle="tab"
                                      href="#tab-id-inn6"
                                      aria-expanded="false"
                                    >
                                      <span>Redeemed Voucher</span>
                                    </a>
                                  </li>
                                </ul>
                                <div className="tab-content">
                                  <div
                                    id="tab-id-inn5"
                                    className="tab-pane fade active in"
                                  >
                                    {/*<h4 className="no-recrds-found active">
                                      Available Voucher<i></i>
                                    </h4>*/}
                                    <div className="tab_mobrow filter_tabin">
                                      <h4 className="tab_mobtrigger inner_tab_border active"></h4>
                                      <div className="order-delivery">
                                        <div className="ord-body">
                                          {}

                                          <div className="voacher-main-div">
                                            <ul className="myvoacher_alldetails">
                                              {this.loadMyVouchers(
                                                this,
                                                "vouchers"
                                              )}
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    id="tab-id-inn6"
                                    className="tab-pane fade"
                                  >
                                    {/*<h4 className="no-recrds-found">
                                      Redeemed Voucher<i></i>
                                    </h4>*/}
                                    <div className="tab_mobrow filter_tabin">
                                    <h4 className="tab_mobtrigger inner_tab_border active"></h4>
                                      <div className="voacher-main-div voucher-remddiv">
                                        <ul className="myvoacher_alldetails">
                                          {this.loadMyUsedVouchers(
                                            this,
                                            "vouchers"
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* next tab */}
                            </div>
                          </div>
                        </div>
            </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />

        <div
          id="receipt-popup" className="mfp-hide receipt_popup vouchar-popup">
          <div className="vouchar-inner">
            <div className="voucher-popup-image">
              {this.state.promodetails.product_thumbnail !== "" &&
              this.state.promodetails.product_thumbnail !== null ? (
                <img
                  src={
                    this.state.productimagePath +
                    this.state.promodetails.product_thumbnail
                  }
                />
              ) : (
                <img
                  src={
                    this.state.promodetails.product_voucher == "c"
                      ? BigcashVouchar
                      : BigproVouchar
                  }
                />
              )}
            </div>
            <div className="voucher-contentmain">
              <h3>Vouchers</h3>
              <h4 className="">{this.state.promodetails.item_name}</h4>
              <span className="expiry-date">
                {this.loadProductDate(this.state.promodetails.expiry_date)}
              </span>

              <h4>
                Available :{" "}
                {this.state.promodetails.order_item_voucher_balance_qty}
              </h4>
              {this.state.promodetails.product_short_description !== "" &&
                this.state.promodetails.product_short_description !== null && (
                  <p>
                    {Parser(
                      stripslashes(
                        this.state.promodetails.product_short_description + ""
                      )
                    )}
                  </p>
                )}

              <div className="addcart_row">
                <p className="sel-quality">Select Quantity</p>
                <div className="addcart-row-child">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "decr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      className="proqty_input"
                      readOnly
                      value="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        this.state.promodetails.promotion_id,
                        "incr",
                        this.state.promodetails.order_item_voucher_balance_qty
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
              <a
                className="btn voucher-btn"
                onClick={this.addToCart.bind(this, this.state.promodetails)}
                href={void 0}
              >
                Redeem Now
              </a>
            </div>
          </div>
        </div>
        {/*} <div id="dvLoading"></div> {*/}

        <div
          className="white-popup mfp-hide popup_sec"
          id="promotion-popup"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header">Success</div>
                <div className="alert_body">
                  <p>Promotion is applied successfully</p>
                  <div className="alt_btns">
                    <a
                      href={void 0}
                      className="btn btn-sm btn_yellow"
                      onClick={this.promotioncheckout.bind(this)}
                    >
                      Ok
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  var overAllcart = Array();
  var cartTotalItmCount = 0;
  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  return {
    activitycount: state.activitycount,
    promotionlist: state.promotionlist,
    overAllcart: overAllcart,
    cartTotalItmCount: cartTotalItmCount,
    promotionreceipt: state.promotionreceipt,
    applypromotion: state.applypromotion,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getPromotionList: (customerParam) => {
      dispatch({ type: GET_PROMOTIONLIST, customerParam });
    },
    getPromotionReceipt: (params) => {
      dispatch({ type: GET_PROMOTIONRECEIPT, params });
    },
    getApplyPromotion: (postData) => {
      dispatch({ type: GET_APPLYPROMOTION, postData });
    },
  };
};

Myvoucher.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Myvoucher)
);
