/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cookie from 'react-cookies';

import Parser from "html-react-parser";

import { companyname } from "../Helpers/Config";
import { GET_STATIC_BLOCK, GET_SOCIALSETTINGS } from '../../actions';

import footerLogo from "../../common/images/footer-logo.png";

import facebookIcon from "../../common/images/facebook-icon.png";
import instagramIcon from "../../common/images/instagram-icon.png";
import youtubeIcon from "../../common/images/youtube-icon.png";


class Footer extends Component {
	
	 constructor(props) {
		super(props);
		this.state = { footer_contact_info:'',footer_about_us:'',footerSocialcontent :'',footer_menus:'' };
	 }
	 
	 componentDidMount() {
		 
			this.props.getStaticBlock();
			this.props.getSocialsetting();
			
			var btn = $('#scrollbutton');

			$(window).scroll(function() {
			  if ($(window).scrollTop() > 300) {
				btn.addClass('show');
			  } else {
				btn.removeClass('show');
			  }
			});

			btn.on('click', function(e) {
			  e.preventDefault();
			  $('html, body').animate({scrollTop:0}, '300');
			});
      }
	  	  
       componentDidUpdate(prevProps) {
		
		if (this.props.socialsettingData != prevProps.socialsettingData) {

			this.setState( { footerSocialcontent: this.props.socialsettingData[0].result_set });
		}
		
		
		if(prevProps.staticblack !== this.props.staticblack) {

			  let footer_contact_info = '',footer_about_us='',footer_menus='';

			  if(Object.keys(this.props.staticblack).length > 0) {

			    this.props.staticblack.map((data, index) => {

				  if(data.staticblocks_slug === 'footer-contact-info') {
					  footer_contact_info = data.staticblocks_description;
				  }

				  if(data.staticblocks_slug === 'footer-about-us') {
					  footer_about_us = data.staticblocks_description;
				  }

				  if(data.staticblocks_slug === 'footer-menus' && cookie.load('UserId')) {
					  footer_menus = data.staticblocks_description;
				  } 
				  if(data.staticblocks_slug === 'footer-menus-logged-out'  && !cookie.load('UserId')){
					  footer_menus = data.staticblocks_description;
				  }

				});
			  }

			  footer_contact_info = (footer_contact_info !== '') ? Parser(footer_contact_info) : footer_contact_info;
			  footer_about_us = (footer_about_us !== '') ? Parser(footer_about_us) : footer_about_us;
			  footer_menus = (footer_menus !== '') ? Parser(footer_menus) : footer_menus;

			  this.setState({footer_contact_info: footer_contact_info,footer_about_us:footer_about_us,footer_menus:footer_menus});
		  }
		
		
	  }
	render() {
        
		var todayTimeSp = new Date();
		var yearSp = todayTimeSp.getFullYear();
		
		let socialsettingData = [];

		let facebook,instagram,email_info,youtube,twitter,linkedin,pinterest ='';

		if(this.state.footerSocialcontent) {

			socialsettingData = this.state.footerSocialcontent;

			if(socialsettingData.socailnetwork_facebook_url) {

			  facebook = <li><a href={socialsettingData.socailnetwork_facebook_url}  target="_blank"  rel="noopener noreferrer" className=""><img src={facebookIcon} /></a></li>

			}

			if(socialsettingData.socailnetwork_instagram_url) {
			  instagram = <li><a href={socialsettingData.socailnetwork_instagram_url} target="_blank"  rel="noopener noreferrer" className=""><img src={instagramIcon} /></a></li>
			}

			if(socialsettingData.socailnetwork_youtube_url){
			  youtube = <li><a href={socialsettingData.socailnetwork_youtube_url} target="_blank" rel="noopener noreferrer"><img src={youtubeIcon} /></a></li>
			}

		  }

		$(document).on('click','.new_login_popup',function(){
		  $.magnificPopup.open({
		  items: {
			src: '#login-popup'
		  },
		  type: 'inline'
			});
		});
		
		return (
				<footer className="footer-main">
							<div className="footer-top">
								<img src={footerLogo} title="Madbar Georges" />
							</div>
							<div className="container">
								<div className="ft-ct-top">
									{this.state.footer_contact_info}
								</div>
								<div className="ft-ct-bottom">
									{/*<div className="ft-bt-col1"> 
										<h4>About Us</h4>
										{this.state.footer_about_us}
									</div>*/}
									{this.state.footer_menus}
									{/*<div className="ft-bt-col5">
										<h4>Follow Us</h4>
										<ul className="social-list">
											{facebook}
											{instagram}
											{youtube}
										</ul>
									</div>*/}
								</div>
							</div>
							
							<div className="copyright-top">
								<div className="running-cycle">
									<div></div>
								</div>
							</div>
							<div className="copyright-section">
								<p>Copyright {yearSp} {companyname}.  All rights reserved. <span>Design By Jankosoft</span></p>
							</div>
							
							<div className="scrolltop" id="scrollbutton"> 
								<a href={void 0}>
									<span>
									<i className="fa fa-angle-double-up ars"></i>
									<i className="fa fa-angle-up ars1"></i>
									<i className="fa fa-angle-up ars2"></i>
									</span>
								</a>
							</div>
							
				</footer>
			    );
	}
}


const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if(Object.keys(state.staticblack).length > 0) {
		 if(state.staticblack[0].status === 'ok') {
			 blacksArr = state.staticblack[0].result_set;
		 }
  }	
  return {
    staticblack: blacksArr,
    socialsettingData: state.socialsettings,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getSocialsetting: () => {
		dispatch({ type: GET_SOCIALSETTINGS });
	  }
  }
}

export default connect(mapStateTopProps, mapDispatchToProps)(Footer);

