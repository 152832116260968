/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cookie from 'react-cookies';
import moment from 'moment';
import { getReferenceID, showAlert, stripslashes, getCalculatedAmount, callImage, showLoader, hideLoader, getOrderDateTime, getPromoCkValue, removeOrderDateTime, removePromoCkValue, resetCrtStyle } from "../Helpers/SettingHelper";
import { appId, apiUrl, deliveryId, madbarId, cateringId, pickupId, CountryTxt } from "../Helpers/Config";
import Parser from 'html-react-parser';
import { GET_CART_DETAIL, UPDATE_CART_DETAIL, DELETE_CART_DETAIL, DESTROY_CART_DETAIL } from '../../actions';
import OrderAdvancedDatetimeSlot from "./OrderAdvancedDatetimeSlot";
import { setMinutes, setHours, getDay, format } from "date-fns";
import productImg from "../../common/images/noimg-400x400.jpg";
import scootyImg from "../../common/images/scooty.png";
import pickupImg from "../../common/images/takeaway_old-b.png";
import cartMpImg from "../../common/images/cart-map.png";
import crossImg from "../../common/images/cross1.png";
import bagImg from "../../common/images/bag_block.svg";

class CartSideBar extends Component {

  constructor(props) {
    super(props);
    var unitNoOne =
      typeof cookie.load("unitNoOne") === "undefined"
        ? ""
        : cookie.load("unitNoOne");
    var unitNoTwo =
      typeof cookie.load("unitNoTwo") === "undefined"
        ? ""
        : cookie.load("unitNoTwo");
    var defaultAvilablityId =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var orderOutletId =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== undefined &&
      typeof cookie.load("orderOutletId") !== "undefined"
        ? cookie.load("orderOutletId")
        : "";
    var orderTAT =
      cookie.load("orderTAT") !== "" &&
      typeof cookie.load("orderTAT") !== undefined &&
      typeof cookie.load("orderTAT") !== "undefined"
        ? cookie.load("orderTAT")
        : "";
    this.state = {cartlistdetail:[], overAllcart: [], cartItems: [], cartDetails: [], cartStatus: '', settings: [], cartTotalItmCount: 0, cartTotalAmount: 0, unitnumber1: unitNoOne, unitnumber2: unitNoTwo, defaultAvilablityId: defaultAvilablityId, seletedAvilablityId: defaultAvilablityId, updateCartResponse: [],startMsg: 0, seletedOutletId: orderOutletId, order_tat_time: orderTAT, initialSlot: true, getDateTimeFlg: "yes",
	};

	}

	componentWillMount() {
		var avltyTxt = cookie.load("defaultAvilablityId");
		/*if(avltyTxt === cateringId) {
			this.props.getCateringCartDetail();
		} else {*/
			this.props.getCartDetail();
		//}
	}

	componentWillReceiveProps(headerProps) {
		if(headerProps.cartTriggerFlg === 'yes') {
			if (
        this.props.match.path !== "/myaccount" &&
        this.props.match.path !== "/myorders" &&
        this.props.match.path !== "/rewards" &&
        this.props.match.path !== "/myreservations" &&
        this.props.match.path !== "/referral" &&
        this.props.match.path !== "/mypromotions" &&
        this.props.match.path !== "/myvouchers" &&
        this.props.match.path !== "/thankyou/:orderId" &&
        this.props.match.path !== "/reservation" &&
        this.props.match.path !== "/reservation-summary" &&
        this.props.match.path !== "/reservation-thankyou" &&
        this.props.match.path !== "/reservation_suggest/:acceptreject/:resid"
      ) {
        headerProps.prpSateValChange("cartflg", "no");
      }
      this.props.getCartDetail();
      resetCrtStyle();
    }


		hideLoader('product-details','class');
		hideLoader('cart_body','class');

    if (this.state.updateCartResponse !== headerProps.updateCartResponse) {
      if (Object.keys(headerProps.updateCartResponse).length > 0) {
        this.setState(
          { updateCartResponse: headerProps.updateCartResponse },
          function () {
            var Response = headerProps.updateCartResponse;

            if (Object.keys(Response).length > 0) {
              if (Response[0].status === "error") {
                if (this.state.startMsg === 1) {
                  this.handleShowAlertFunct("Error", Response[0].message);
                  this.setState({ startMsg: 0 });
                }
              }
            }
          }
        );
      }
    }

    if (this.state.cartDetails !== headerProps.cartDetails) {
      if (Object.keys(headerProps.cartDetails).length > 0) {
        this.setState({cartDetails: headerProps.cartDetails});
        if (this.state.initialSlot === true) {
          this.setState({ getDateTimeFlg: "yes", initialSlot: false });
          headerProps.setdateTimeFlg("tmflg", "yes");
        }
      }
    }

	}
  
	componentDidMount() {
		
	}

	closeCartlist() {
		$("body").removeClass("cart-items-open");
		$(".hcartdd_trigger").removeClass("active");
		$(".hcart_dropdown").removeClass("open");
	}

	changeAddrrFun(event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#warning-popup",
      },
      type: "inline",
    });
    return false;
  }

  handleAddrChange(event) {
    if (event.target.name === "unit_no1") {
      cookie.save("unitNoOne", event.target.value, { path: "/" });
      this.setState({ unitnumber1: event.target.value });
    } else if (event.target.name === "unit_no2") {
      cookie.save("unitNoTwo", event.target.value, { path: "/" });
      this.setState({ unitnumber2: event.target.value });
    }
  }
	
	removePromoFun() {
		removePromoCkValue();
		this.props.getCartDetail();
	}

	convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      },
      function () {
        this.setOrderOutletDateTimeDataNew();
      }.bind(this));
    } else if (field == "ordSlotDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_date: value,
        seleted_ord_time: ordTime,
        seleted_ord_slot: ordTime,
        seleted_ord_slotTxt: ordTime,
        seleted_ord_slot_str: ordTime,
        seleted_ord_slot_end: ordTime,
      });
    } else if (field == "ordSlotTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({
        seleted_ord_time: tmSltArr["startTime"],
        seleted_ord_slot: tmSltArr["ordSlotVal"],
        seleted_ord_slotTxt: tmSltArr["ordSlotLbl"],
        seleted_ord_slot_str: tmSltArr["ordSlotStr"],
        seleted_ord_slot_end: tmSltArr["ordSlotEnd"],
      },
      function () {
        this.setOrderOutletDateTimeDataNew();
      }.bind(this));
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeDataNew() {
    var seletedOrdDate = this.state.seleted_ord_date;
      var seletedOrdTime = this.state.seleted_ord_time;
      if(seletedOrdDate !== '' && seletedOrdTime !== '' && seletedOrdDate !== null && seletedOrdTime !== null) {
    
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime = this.convPadNew(OrderHours)+":"+this.convPadNew(OrderMunts)+":"+this.convPadNew(OrderSecnd);
        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        this.props.getCartDetail();
    }
  
  }

  convPadNew(d) {
    var rstVl = d < 10 ? "0" + parseInt(d) : d.toString();
    return rstVl.toString();
  }
	
	cartDetailsList() {
		
		var cartItemsArr = this.props.cartItems;
		
		if (Object.keys(cartItemsArr).length > 0) {
		
		var cartDetailsArr = this.props.cartDetails;
		var promoTionArr = getPromoCkValue();
		
		var globalSettings = Array();
	    if(Object.keys(this.props.globalsettings).length > 0) {
		  if(this.props.globalsettings[0].status === 'ok') {
			  globalSettings = this.props.globalsettings[0].result_set;
		  }
	    }
	    /*var calculatedAmount = getCalculatedAmount(globalSettings,cartDetailsArr,promoTionArr);*/
		
		var zoneDetails = this.props.zonedetails;
	    var calculatedAmount = getCalculatedAmount(globalSettings,zoneDetails,cartDetailsArr,promoTionArr);
		
		var orderDateTime = (typeof cookie.load('orderDateTime') === 'undefined') ? '' : cookie.load('orderDateTime');
		var orderTAT = (typeof cookie.load('orderTAT') === 'undefined') ? '' : cookie.load('orderTAT');
		var orderDateTmTxt = getOrderDateTime(orderDateTime,orderTAT);
		
		return (<div className="hcart_dropdown">
					<div className="hcart_tt">
					 {/*<div
              id="cart-close-span"
              onClick={this.closeCartlist.bind(this)}
            ></div>*/} 
								<h3>YOUR ORDER DETAILS</h3>
								<span className="close_product_orders" onClick={this.closeCartlist.bind(this)} >
									{" "}
									{/* <img
										src={crossImg}
										alt=""
										onClick={this.closeCartlist.bind(this)}
									/> */}
								</span>
							
					</div>
					<div className="hcart_scrollarea">
						<div className="cart_table">
							<div className="cart_body">
							   {(cookie.load('defaultAvilablityId') === deliveryId || cookie.load('defaultAvilablityId') === madbarId) ?<div className="delivery-cart-div">
								{/* <div className="cart-direction">
								  <img className="cart-direction-left" src={scootyImg} />
								  <img className="cart-direction-right" src={cartMpImg} />
								</div> */}
								
								<div className="col-sm-cls cart_left text-left">
									<h4>Order Handled By</h4>
									<p>{cookie.load('orderOutletName')}</p>
									<p>{cookie.load('orderHandledByText')}</p>
								</div>
								<div className="col-sm-cls cart_right text-right">
									<h4>Delivery Location</h4>
									<p>{cookie.load('orderDeliveryAddress')}</p>   
									<p>{CountryTxt} {cookie.load('orderPostalCode')}</p>
									<div className="hcart_add_innr_loca">
										<p>#</p>
										<div className="input_field">
										<input
											type="text"
											className="form-control"
											value={this.state.unitnumber1}
											name="unit_no1"
											onChange={this.handleAddrChange.bind(this)}
										/>
										</div>
										<div className="input_field">
										<input
											type="text"
											className="form-control"
											value={this.state.unitnumber2}
											name="unit_no2"
											onChange={this.handleAddrChange.bind(this)}
										/>
										</div>
									</div>
									<p><span>
										<a className="cart-loc-change" href="/" 
											onClick={this.changeAddrrFun.bind(this)}>Change Delivery Location
										</a>
										</span>
									</p>   
								</div>
									
								</div> : <div className="pickup-cart-div"><div className="cart-direction">
								  <img className="cart-direction-left" src={pickupImg} />
								</div>
								<div className="cart_row cart-header-first">
									<div className="row-replace">
										<div className="col-xs-12 cart_left text-center">
											<h4>Pickup Location</h4>
											<p>{cookie.load('orderOutletName')}</p>
											<p>{cookie.load('orderHandledByText')}</p>
										</div>
									</div>
								</div></div>}
								{/*<div className="cart_row cart-header-second">
									<div className="row-replace">
										<div className="col-sm-cls text-left">
											<h5>{(cookie.load('defaultAvilablityId')===deliveryId)?"Delivery":"Pickup"} Date</h5>
											<h3>{moment(orderDateTmTxt).format("DD/MM/YYYY")}</h3>
										</div>
										<div className="col-sm-cls text-right">
											<h5>{(cookie.load('defaultAvilablityId')===deliveryId)?"Delivery":"Pickup"} Time</h5>
											<h3>{moment(orderDateTmTxt).format("hh:mm A")}</h3>    
										</div>
									</div>
								</div>*/}
								<div className="cart_row cart-header-second">
									<div className="col-sm-cls text-left">
										{(cookie.load("defaultAvilablityId") === deliveryId || cookie.load("defaultAvilablityId") === madbarId) && (
											<OrderAdvancedDatetimeSlot
												{...this.props}
												hdrState={this.state}
												setdateTimeFlg={this.setdateTimeFlg}
												labelDateName="Delivery Date"
												labelTimeName="Delivery Time"
											/>
										)} 
										{cookie.load("defaultAvilablityId") === pickupId && (
											<OrderAdvancedDatetimeSlot
												{...this.props}
												hdrState={this.state}
												setdateTimeFlg={this.setdateTimeFlg}
												labelDateName="Pickup Date"
												labelTimeName="Pickup Time"
											/>
										)}
									</div>
								</div>
								<div className="product_orders_top">
									<h4>Your Items</h4>
									<div className="product_clear_cart">
										<a href="#" onClick={this.clearCartItm.bind(this)} className="hclear_cart" title="CLEAR CART">CLEAR CART</a>
									</div>
								</div>
								
								{this.cartItemList()}
								
							</div>
							
							
							<div className="cart_footer">
								<div className="cart_row">
									<p className="text-uppercase">SUBTOTAL</p>
									<span>${calculatedAmount['cartSubTotalAmount']}</span>
								</div>
								{(parseFloat(calculatedAmount['deliveryCharge'])>0) &&  <div className="cart_row">
							
											<p className="text-uppercase">Delivery</p>
									
											<span>${parseFloat(calculatedAmount['deliveryCharge']).toFixed(2)}</span>
										
								</div>}
								{(parseFloat(calculatedAmount['additionalDelivery'])>0) &&  <div className="cart_row">
									<p className="text-uppercase">Additional Delivery</p>
									<span>${parseFloat(calculatedAmount['additionalDelivery']).toFixed(2)}</span>
								</div>}
								{(parseFloat(calculatedAmount["packagingCharge"]) > 0) && <div className="cart_row">	
				                    
				                        <p className="text-uppercase">Packaging Charges</p>	
				                      
				                        <span>${parseFloat(calculatedAmount['packagingCharge']).toFixed(2)}</span>
				                      
				                </div>}
								{(parseFloat(calculatedAmount['promotionAmount'])>0) && <div className="cart_row promo_add">
								
								
									<p className="text-uppercase">Promo Code</p>
							
									<span>${parseFloat(calculatedAmount['promotionAmount']).toFixed(2)}</span>
									
								
									<a href="#" onClick={this.removePromoFun.bind(this)} className="cart_remove"></a>
								</div>}
								{(parseFloat(calculatedAmount['orderGstAmount'])>0) && <div className="cart_row">
									
											<p className="text-uppercase">GST ({calculatedAmount['orderDisplayGst']} %)</p>
										
											<span>${parseFloat(calculatedAmount['orderGstAmount']).toFixed(2)}</span>
										
								</div>}

								{(parseFloat(calculatedAmount['voucherDiscountAmount'])>0) && <div className="cart_row">
									
											<p className="text-uppercase">Voucher Disocunt Amount</p>
										
											<span>${parseFloat(calculatedAmount['voucherDiscountAmount']).toFixed(2)}</span>
										
								</div>}
																
								<div className="cart_row grant-total-cls">
							
											<p className="text-uppercase">Total</p>
									
											<span>${calculatedAmount['grandTotalAmount']}</span>
										
								</div>
							   
							   {parseFloat(cartDetailsArr.cart_special_discount) > 0 && 
								
								<div class="member-discount-total">* {cartDetailsArr.cart_special_discount_type} ${cartDetailsArr.cart_special_discount} Applied</div>
								
								}
							   
						</div>
						{(cookie.load("defaultAvilablityId") === deliveryId || cookie.load("defaultAvilablityId") === madbarId) && this.loadDeliveryPercentage()}
					</div>
						
					</div>
					<div className="cart_action">
						<div className="cartaction_bottom">
							<Link to={"/checkout"} className="btn btn_pink cartaction_checkout" title="Checkout Now">Checkout</Link>
						</div>
					</div>
				</div>);
		} else {
			return '';
		}
		
	}
	
	/* this  function used to load delivery percentage */
    loadDeliveryPercentage() {
		var freeDeliveryAmnt = 0;
		var DeliveryAmnt = 0;
		var remainAmnt = 0;
		var delPercentage = 0;
		
		var cartItemsArr = this.props.cartItems;
		
		if (Object.keys(cartItemsArr).length > 0) {
		
			var cartDetailsArr = this.props.cartDetails;
			var promoTionArr = getPromoCkValue();
			
			var globalSettings = Array();
			if(Object.keys(this.props.globalsettings).length > 0) {
			  if(this.props.globalsettings[0].status === 'ok') {
				  globalSettings = this.props.globalsettings[0].result_set;
			  }
			}
		   
			var zoneDetails = this.props.zonedetails;
			var calculatedAmount = getCalculatedAmount(globalSettings,zoneDetails,cartDetailsArr,promoTionArr);
			
				freeDeliveryAmnt = parseFloat(calculatedAmount['freeDeliveryAmnt']);
				DeliveryAmnt = parseFloat(calculatedAmount['deliveryCharge']);
			var subTotal = parseFloat(calculatedAmount['cartSubTotalAmount']);
			var percentage = (subTotal * 100) / freeDeliveryAmnt;
				percentage = Math.round(percentage);
				delPercentage = percentage;
				remainAmnt = parseFloat((freeDeliveryAmnt - subTotal)).toFixed(2);
		}
		
		

		if(DeliveryAmnt > 0 && freeDeliveryAmnt > 0 && remainAmnt > 0) {

		  return (<div className="progress_bar_div">
			<div className="indication">
			  <div className="indication_progress">
				<span className="progress_bar" style={{ width: delPercentage + "%" }} />
			  </div>
			  <p className="help-block">${remainAmnt} more to FREE delivery!</p>
			</div>
		  </div>);
		}

    }
	
	cartItemList() {
		var cartItemsArr = this.props.cartItems;
		if (Object.keys(cartItemsArr).length > 0) {
			return cartItemsArr.map((product, index) =>
						<div className="cartsidebar_row product-details" id={'rowcartid-'+product.cart_item_id} key={index}>
						
							<div className="cart_lhs">
								<div className="cart_img">
									<a href={void 0} title="">
									{(product.cart_item_product_image !== '')?<img src={product.cart_item_product_image} width="110" height="67" alt="" />:<img src={productImg} width="110" height="67" alt="" />}
									</a>
								</div>
								<div className="cart_info text-left">
									<h4>{stripslashes(product.cart_item_product_name)}</h4>
									<h4> {product.cart_item_voucher_id !== "" && product.cart_item_voucher_id != null	? "Discount Applied"  : ""}</h4>							
									{this.loadModifierItems(product.cart_item_type, product.modifiers, product.set_menu_component)}
									
									{product.cart_item_special_notes !== '' && <p className="help-block">{stripslashes(product.cart_item_special_notes)}</p>}


									{parseFloat(product.cart_item_promotion_discount) > 0 &&

									<span class="member-discount-desc">$ {product.cart_item_promotion_discount} {product.cart_item_promotion_type} discount Applied</span>
									}							
								</div>
							</div>
							<div className="cart_rhs text-right">
								<div className="cart_price"><p>${product.cart_item_total_price}</p></div>
								{product.cart_item_voucher_product_free != 1 ? (
								<div className="qty_bx">
									<span className="qty_minus"
									onClick={this.updateCartQty.bind(this, product, "decr")}
									>
									-
									</span>
									<input type="text" value={product.cart_item_qty} readOnly />
									<span className="qty_plus"
									onClick={this.updateCartQty.bind(this, product, "incr")}
									>
									+
									</span>
								</div>
								) : (
								<div className="qty_bx free_product">
									<span className="qty_minus"></span>
									<input type="text" value={product.cart_item_qty} readOnly />
									<span className="qty_plus"></span>
								</div>
								)}																			
							</div>
							
							<a href={void 0} onClick={this.deleteCartItm.bind(this, product)} className="cart_remove"></a>
						</div>);
		}
	}
	
	 /* this function used to load modifer items */
	 loadModifierItems(itemType, modifiers, combo) {
		 var len = modifiers.length;
		var comboLen = combo.length;
		var html = '<p class="cart_extrainfo">';

		var temp_html = "";

		if (itemType === "Modifier" && len > 0) {
		  for (var i = 0, length = len; i < length; i++) {
			var modName = modifiers[i]["cart_modifier_name"];
			var modval = modifiers[i]["modifiers_values"][0]["cart_modifier_name"];
			var modVlPrice =
			  modifiers[i]["modifiers_values"][0]["cart_modifier_price"];
			var newModVlPrice = modVlPrice > 0 ? " (+$" + modVlPrice + ")" : "";
			temp_html +=
			  "<span>" +
			  modName +
			  ":</span> <span>" +
			  modval +
			  newModVlPrice +
			  "</span> ";
		  }

		  html += temp_html + "</div>";
		  var reactElement = Parser(html);
		  return reactElement;
		} else if (itemType === "Component" && comboLen > 0) {
		  for (var i = 0, length = comboLen; i < length; i++) {
			var comboName = combo[i]["menu_component_name"];
			var comboVal = this.showComboProducts(combo[i]["product_details"]);

			if (combo[i]["product_details"][0]["modifiers"].length) {
			  html +=
				"<p class='cart_extrainfo'>" +
				comboName +
				": <span>" +
				comboVal +
				" " +
				this.showComboModifiers(
				  combo[i]["product_details"][0]["modifiers"]
				) +
				"</span></p> ";
			} else {
			  html +=
				"<p class='cart_extrainfo'>" +
				comboName +
				": <span>" +
				comboVal +
				" " +
				this.showComboModifiers(
				  combo[i]["product_details"][0]["modifiers"]
				) +
				"</span> </p>";
			}
		  }
		  html += "</p>";
		  var reactElement = Parser(html);
		  return reactElement;
		}
	}


	/* show combo products  list */
	showComboProducts(combos) {
		var lenCombo = combos.length;
		var html = " ";
		if (lenCombo > 0) {
		  for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
		   if(parseInt(combos[r]['cart_menu_component_product_qty']) > 0) {	  
			var comboPro = combos[r]['cart_menu_component_product_name'];
			var comboQty = combos[r]['cart_menu_component_product_qty'];
			var comboPrice = combos[r]['cart_menu_component_product_price'];
			var newPrice = (comboPrice > 0) ? " (+$" + comboPrice + ")" : "";
			html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
		   }
		  }
		  return html;
		}
		return "";
	}

	/* this function used to show combo modifieirs list */
	showComboModifiers(modifiers) {
		var lenMod = modifiers.length;
		var html = '<div >';
		if (lenMod > 0) {
		  for (var i = 0, length = lenMod; i < length; i++) {
			var modName = modifiers[i]['cart_modifier_name'];
			var modval = modifiers[i]['modifiers_values'][0]['cart_modifier_name'];
			var modValPrice = modifiers[i]['modifiers_values'][0]['cart_modifier_price'];
			var newModValPrice = (modValPrice > 0) ? " (+$" + modValPrice + ")" : "";
			html += "<p><b>" + modName + ":</b> </p><p> " + modval + newModValPrice + "</p> ";
		  }
		  html += '</div>';

		  return html;

		}

		return "";
	}
	
	updateCartQty(itemArr, type) {
		
		var productId = itemArr.cart_item_product_id;
		var cartItemId = itemArr.cart_item_id;
		var cartQty = itemArr.cart_item_qty;
		var totalItmCount = this.props.cartTotalItmCount;
		var orderVoucherId = itemArr.cart_voucher_order_item_id;
		
		showLoader('rowcartid-'+cartItemId,'Idtext');
		
		if(type === 'decr') {
			cartQty = parseInt(cartQty) - 1;
			if(parseInt(totalItmCount) === 0) {
				
			} else if(parseInt(cartQty) === 0) {
				this.props.deleteCartDetail(cartItemId);
			} else {
				this.props.updateCartDetail(productId,cartItemId,cartQty,orderVoucherId);
			}
		} else {
			cartQty = parseInt(cartQty) + 1;
			this.props.updateCartDetail(productId,cartItemId,cartQty,orderVoucherId);
		}

		this.setState({ startMsg: 1 });
		removePromoCkValue();
	}
	
	deleteCartItm(itemArr) {
		var cartItemId = itemArr.cart_item_id;
		showLoader('rowcartid-'+cartItemId,'Idtext');
		this.props.deleteCartDetail(cartItemId);
		removePromoCkValue();
		this.props.history.push('/products');
	}
	
	clearCartItm() {
  if (confirm("Are you sure to delete?") == true) {
		showLoader('cart_body','class');
		this.props.destroyCartDetail();
		removePromoCkValue();
		this.props.history.push('/products');
	}
	}
	
	gotoCateringChkut() {
		var cartTotal = this.props.cateringCartTotalItmCount;
		/*if(parseFloat(cartTotal)>0) {*/
		   cookie.save("cateringCheckoutFromCart",'Yes');
		   this.props.history.push('/catering');
		/*}*/
	}
	
    render() {
	    
		var currenturl = window.location.href;
        var substring1 = "checkout";
		
		var avltyTxt = cookie.load("defaultAvilablityId");
		
        return (<li className="htico_cart">
				  {(avltyTxt === cateringId) ? <a href={void 0} onClick={this.gotoCateringChkut.bind(this)} className="hcartdd_trigger" title=""><i></i><span className="hcart_round">{this.props.cateringCartTotalItmCount}</span></a> : <a href={void 0} className="hcartdd_trigger" title="">
					<img src={bagImg} alt="Cart" />
					<span className="hcart_round">{this.props.cartTotalItmCount}</span></a>}
				  {(currenturl.includes(substring1) !== true && avltyTxt !== cateringId) ? this.cartDetailsList() : ''}
			    </li>);
    }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var updateCartResponse = Array();
  var cartTotalItmCount = 0;
  var cartStatus = '';

  if (Object.keys(state.updatecartdetail).length > 0) {
    if (state.updatecartdetail[0].status === "error") {
      updateCartResponse = state.updatecartdetail;
    }
  }

  if(Object.keys(state.cartlistdetail).length > 0) {
	var resultSetArr = !("result_set" in state.cartlistdetail[0])
	? Array()
	: state.cartlistdetail[0].result_set;
	  if(state.cartlistdetail[0].status === 'ok' && Object.keys(resultSetArr).length > 0) {
		overAllcart = resultSetArr;  
		cartDetails = resultSetArr.cart_details;
		cartItems = resultSetArr.cart_items;
		cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
		cartStatus = 'success'; 
	  } else {
		cartStatus = 'failure';
	  }
  }
  
  return {
    overAllcart: overAllcart,
    cartDetails: cartDetails,
    cartItems: cartItems,
    cartTotalItmCount: cartTotalItmCount,
    cartStatus: cartStatus,
    updateCartResponse: updateCartResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
	updateCartDetail: (productId,cartItemId,cartQty,orderVoucherId) => {
      dispatch({ type: UPDATE_CART_DETAIL, productId, cartItemId, cartQty, orderVoucherId });
    },
	deleteCartDetail: (cartItemId) => {
      dispatch({ type: DELETE_CART_DETAIL, cartItemId });
    },
	destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
	
  }
}
export default connect(mapStateTopProps, mapDispatchToProps)(CartSideBar);
